.locationInfo {
  width: 100%;
  margin-bottom: 40px;

  table {
    width: 100%;

    td {
      width: 50%;
    }

    td:nth-child(1) {
      border-right: 12px solid transparent;
    }

    td:nth-child(2) {
      border-left: 5px solid transparent;
    }
  }
}