.colabContainer {
    font-family: 'Roboto';
    margin: auto;
    margin-bottom: 30px;


    .heading {
        font-family: 'Bitter';
        font-size: 20px;
        font-weight: 700;
        color: #111111;
    }

    .box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-flow: row;
        align-items: flex-start;
        margin-top: 20px
    }


}