.partnersFiltersContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 35px;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .collapsableComponent {
        margin-top: 20px;
        border-radius: 8px;
        background: #F7F8FA;
        border-radius: 8px;
        height: fit-content;
        padding: 10px;
    }
}