:root {
  --label-color: orange;
}

.container{
  margin-top: 58px;

  /* .socialContainer:first-child {
    margin-top: 14px;
  } */
}

.socialContainer {
  background-color: #FFFFFF;
  border-radius: 10px;
  margin: 16px 0;
  padding: 0.5em 0 ;
  height: 6vh;
  display: flex;
  flex-flow: row;
  .code, .name {
    color: #111111;
    font-size: 16px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .code {
    font-weight: 700;
    margin-left: 2%;
  }
  .name{
    font-weight: 700;
    margin-left: 12%;
  }
  .typeCode {
    height: 100%;
    width: 0.5em;
    background-color: var(--label-color);
    border-radius: 0 5px 5px 0;
  }
}