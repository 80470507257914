.titleValueContainer {
  background-color: #F5F5F5;
}

.editableTitleValueContainer {
  margin-bottom: 16px;
  background-color: #FFFFFF;
  min-width: 150px;

  input {
    border: none;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    color: #111111;
    margin-bottom: -5px;
  }
}

.title {
  font-size: 14px;
  font-weight: 400;
  color: #696969;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 2;
}



.titleIcon {
  margin-left: 31px;
}

.value {
  font-size: 16px;
  font-weight: 700;
  height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #111111;

}

.titleValueContainer,
.editableTitleValueContainerRO {
  background-color: #F5F5F5;
  border-bottom: 1px solid #C0C0C0;
  border-radius: 5px 5px 0 0;
  padding: 12px 16px;
  margin-bottom: 16px;


  input {
    border: none;
    width: 100%;
    background-color: #F5F5F5;
    color: #111111;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  div {
    font-family: 'Roboto';
  }
}

.tableMargin {
  margin-bottom: 12px;
}

.editableTitleValueContainer {
  background-color: white;
  height: 35px;
  border-bottom: 1px solid #C0C0C0;
  border-radius: 5px 5px 0 0;
  padding: 12px 16px;
  min-width: 150px;

  div {
    font-family: 'Roboto';
  }

  input {
    border: none;
    width: 100%;
    color: #111111;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #696969;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.MuiInput-input) {
    height: fit-content;
  }

  :global(.MuiIconButton-root) {
    padding: 0px;
  }

  &.error {
    border-bottom: 1px solid #ff0000;

    .title {
      color: #ff0000;
    }
  }
}

.editableTitleValueAutocomplete {
  padding: 9px 16px 8px;
  background-color: white;

  input {
    background-color: white;
  }

  padding: 13px 16px;
  height: 33px;

  &.error {
    border-bottom: 1px solid #ff0000;

    .title {
      color: #ff0000;
    }
  }

}

.contrast {
  background-color: rgba(245, 252, 255, 1);

  input {
    background-color: rgba(245, 252, 255, 1);
  }
}

.titleValueIcon {
  display: flex;

  .title,
  .value {
    margin-left: 12px;
  }

  .startIcon {
    color: rgba(0, 0, 0, 0);
    margin-top: 3px;
  }

}

.input {
  display: flex;
  align-items: flex-start;

  .endIcon {
    margin-top: -7px;
  }
}