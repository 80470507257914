.server-error-details {
  height: 100%;

  .server-error-details-content {
    margin: 50px 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    svg {
      width: auto;
      height: auto;
      margin-bottom: 32px;
    }

    .title {
      font-family: 'Bitter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 60px;
      text-align: center;
    }

    .subtitle {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
    }
  }
}