.popup {
    .addImages {
        font-family: Roboto;
        font-size: 16px;
        color: rgba(255, 0, 0, 1);
        display: flex;
        align-items: center;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0px;

        &:hover {
            background-color: rgba(0, 0, 0, 0);
            color: rgba(223, 2, 2, 1);
        }

        &:active {
            color: rgba(188, 0, 0, 1);
        }
    }
}

.uploadedImage {
    display: flex;
    justify-content: center;
    height: calc(100% - 230px);

    img {
        height: 29vh;
        border-radius: 15px;
    }
}

.errorDialog {
    min-height: 407px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.buttonFooter {
    justify-content: center;
    margin: 30px;

    button {
        height: 49px;
        width: 155px;
        font-family: 'Roboto';
        font-size: 12px;
        text-transform: none;
    }

    .cancelButton {
        color: rgba(255, 0, 0, 1);
        border: 1px solid rgba(255, 0, 0, 1);

        &:hover {
            color: rgba(223, 2, 2, 1);
        }

        &:active {
            color: rgba(188, 0, 0, 1);
        }
    }

    .uploadButton {
        color: white;
        background-color: rgba(255, 0, 0, 1);

        &:hover {
            background-color: rgba(223, 2, 2, 1);
        }

        &:active {
            background-color: rgba(188, 0, 0, 1);
        }
    }
}


@media (max-width: 1500px) {
    .fullScreenContent {
        height: 79vh;

        .imageContainer {
            height: 61vh;
        }
    }
}