.exportContainer {
  z-index: 3;
  button {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    textTransform: 'none';
    color: red !important;
  }

  .buttonDisable {
    opacity: 25%;
    img {
      width: 25px;
    }
  }
}

.space {
  width: 30px;
}

.dialog {
  min-width: 100px !important;
}

.dialogContent {
  max-width: 535px;
  min-width: 450px;
  text-align: center;
  padding: 4em;
  padding-top: 6em;
  padding-bottom: 6em;
  margin: 0 auto;

  p {
    font-family: Bitter;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(17, 17, 17, 1);
    margin-bottom: 2em;
  }
}

.dialogContent {
  .buttonConfirm {
    background: rgba(255, 0, 0, 1);
    text-transform: none;
    color: #FFFFFF;
    border-color: rgba(255, 0, 0, 1);
    min-width: 150px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;

    &.apply {
      color: #FFFFFF;
      border-radius: 5px;
    }

    &:hover {
      background-color: rgba(223, 2, 2, 1);
    }

    &:active {
      background-color: rgba(188, 0, 0, 1);
    }

    &.close {
      color: #FFFFFF;
      background-color: rgba(188, 0, 0, 1);
    }
  }

  .buttonDiscard {
    text-transform: none;
    color: rgba(255, 0, 0, 1);
    border-color: rgba(255, 0, 0, 1);
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 150px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;

    &.apply {
      color: #FFFFFF;
      border-radius: 5px;
    }

    &:hover {
      background: none;
      color: rgba(223, 2, 2, 1);
      border-color: rgba(223, 2, 2, 1);
    }

    &:active {
      background: #BC0000;
    }

    &.close {
      color: #111111;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.dialogContentError{
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 8em 0 5em 0;
  .buttonConfirm {
    background: rgba(255, 0, 0, 1);
    text-transform: none;
    color: #FFFFFF;
    border-color: rgba(255, 0, 0, 1);
    min-width: 150px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;

    &.apply {
      color: #FFFFFF;
      border-radius: 5px;
    }

    &:hover {
      background-color: rgba(223, 2, 2, 1);
    }

    &:active {
      background-color: rgba(188, 0, 0, 1);
    }

    &.close {
      color: #FFFFFF;
      background-color: rgba(188, 0, 0, 1);
    }
  }
  .serverError {
    position: relative;
    width: 697px;
    margin-top: -15%;

    background: #FFFFFF;
    border-radius: 20px;
  
    .serverErrorContent {
      margin: 50px 0 20px 0;

      svg {
        width: 235px;
        height: auto;
        margin-bottom: 32px;
      }
  
      .title {
        font-family: 'Bitter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 0.2em;
      }
  
      .subtitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        margin-top: 10px;
      }
    }
  
    .defaultError {
      margin: 8em 0 2em 0;  
      .title {
        font-family: 'Bitter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 0.2em;
      }
      .title2 {
        font-family: 'Bitter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 3em;        
      }
  
      .subtitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        margin-top: 25px;
      }
    }
  }
}

.buttonDisableExport{
  opacity: 25%;
}