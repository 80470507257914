.partnersContainer {
	font-family: 'Roboto';
	width: 95%;
	margin: auto;
	.partnerWrapper {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		margin-bottom: 16px;
		.lastUpdate {
		  color: #7A7A7A;
		  font-size: 14px;
		  font-family: 'Roboto';
		  line-height: 17.5px;
		  .lastUpdateDate{
			color:#111111;
		  }
		}
	}
	.heading {
		font-family: 'Bitter';
		font-size: 20px;
		font-weight: 700;
		color: #111111;
	}	
	.boxes {
		width: 100%;
		display: flex;
		flex-flow: row;
		flex-wrap: wrap; 
		justify-content: flex-start;
		column-gap: 24px;
	}
	.boxContainer {
		width: calc(33% - 12px);
		min-width: fit-content;
		max-width: 600px;
		height: fit-content;
		display: flex;
		flex-flow: column;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	
}