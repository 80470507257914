.content-map {
    .map-div {
        >div {
            border-radius: 20px;
        }
    }
}


.dangerLevel {
    &.en {
        color: #CC6633;
    }

    &.vu {
        color: #E3B23F;
    }

    &.ce {
        color: #CC3333;
    }

    &.lc {
        color: #006666;
    }

    &.nt {
        color: #006666;
    }
}