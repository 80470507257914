.documentsContainer {
    font-family: 'Roboto';
    margin: auto;
    margin-bottom: 16px;

    

    .heading {
        font-family: 'Bitter';
        font-size: 20px;
        font-weight: 700;
        color: #111111;
    }

    .documentRow {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 24px;
       
        //margin-bottom: 16px;

        &.editableDocument {
            #ps1 {
                width: 13%;
                min-width: 145px;
            }

            #ps2 {
                flex-basis: 100%;
                min-width: 222px;
            }

            .delete {
                width: 4%;

            }
        }

        &.noEditableDocument {
            margin-top: 16px;
            #ps1 {
                width: 12.5%;
                min-width: 145px;
            }

            #ps2 {
                flex-basis: 100%;
                min-width: 222px;
            }
        }

    }
}