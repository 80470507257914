.container {
  margin: auto;
}

.heading {
  font-family: 'Bitter';
  font-size: 20px;
  font-weight: 700;
  color: #111111;
  display: flex;
  justify-content: space-between;

  .info {
    vertical-align: bottom;
    color: #696969;
    margin-left: 5px;
  }


}



.carouselContainer {
  margin-top: 0;
  width: 92%;
  margin: auto;
  margin-bottom: 56px;
}

.screenBox {
  position: relative;
  text-align: center;

  img {
    width: 480px;
    margin-top: -270px;
  }
}

.fullScreenButton {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
}

.fullScreenContent {
  text-align: center;
  margin: 4em 4em 0 4em;
  display: flex;
  flex-direction: column;
  height: 90vh;

  .imageContainer {
    width: 100%;
    max-height: inherit;
    height: 75vh;
    display: flex;
    justify-content: center;
    max-width: 100%;

    img {
      border-radius: 10px;
      max-width: 100%;
      height: 100%;
      width: auto;
    }
  }

  .fullScreenTitleContainer {
    height: 5px;

    .fullScreenTitle {
      font-family: Bitter;
      font-size: 20px;
      font-weight: 700;
      line-height: 0;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .fullScreenSubTitleContainer {
    max-height: 50px;
    height: 50px;

    .fullScreenSubTitle {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      max-height: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .fullScreenFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    height: 60px;

    .buttons {
      width: 243px;
    }
  }
}

.closeFullScreen {
  top: 2vh;
  right: 1vw;
  position: absolute;

  img {
    width: 27px;
    height: 27px;
  }
}

.info {
  width: calc(100% - 243px);
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: baseline;


  .iconicButton {
    background-color: red !important;
    color: white;
    text-transform: lowercase;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    height: 49px;
    width: 155px;
    margin: 0 20px;
    text-align: center;
    padding-left: 10px;
    padding-right: 8px;
    border-radius: 5px;

    span:first-letter {
      text-transform: capitalize;
    }

    &:hover {
      background-color: rgba(223, 2, 2, 1);
    }

    &:active {
      background-color: rgba(188, 0, 0, 1);
    }

    span {
      margin-right: 3px;
    }
  }

  .deleteButton {
    font-size: 12px;
    height: 49px;
    max-width: 48px;
    min-width: 48px;
    background: rgba(117, 117, 117, 1) !important;
    border-radius: 8px;

    &:hover {
      background-color: rgba(120, 120, 120, 1);
    }

    &:active {
      background-color: rgba(117, 117, 117, 1);
    }

  }
}


.emptyPhotoContainer {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 56px;

  p {
    max-width: 382px;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 1em;
  }
}

.dialogLeftArrow {
  position: absolute;
  top: calc(50% - 60px);
  left: 20px;
}


.dialogRigthArrow {
  position: absolute;
  top: calc(50% - 60px);
  right: 10px;
}

@media (max-width: 1500px) {
  .fullScreenContent {
    height: 79vh;

    .imageContainer {
      height: 61vh;
    }
  }
}


@media (max-width: 1399px) {
  .screenBox {
    img {
      width: 320px;
      margin-top: -179px;
    }
  }
}

.errorContainer {
  display: inline;

  .errorIcon {
    vertical-align: bottom;
    color: red;
    margin-left: 5px;
  }

  .errorText {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: red;
    display: inline;
    margin-left: 5px;
  }
}