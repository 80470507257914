.deleteBtn {
  border-radius: 8px;
  border: none;
  background-color: #696969;
  height: 60px;
  width: 60px;
}
.deleteBtn:hover {
  cursor: pointer;
  background-color: #414141
}
.deleteBtn:active{
  background-color: #111111;
}