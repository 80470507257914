.addBtnContainer {
	position: relative;
  width: 100%;
  height: 20px;
	.addBtn {
		position: absolute;
		right: 0;
		width: fit-content;
	}
}
.workUnitsContainer {
  table {
    margin-top: 9px;
    width: 100%;
  }
  .noDataPic {
    margin-top: 16px;
    text-align: center;
    img {
      width: 232px;
      height: 180px;
    }
    p {
      padding-top: 10px;
      width: 400px;
      margin: 16px auto;
    }
  }
}