.descriptionMultiLanContainer {
  .heading {
    font-family: 'Bitter';
    font-size: 20px;
    font-weight: 700;
    color: #111111;

    .info {
      vertical-align: bottom;
      color: #696969;
      margin-left: 5px;
    }
  }

  .editor>div:first-of-type {
    border: none;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 2px solid #C0C0C0;
    height: 270px;
  }

  .editor :global(.public-DraftEditorPlaceholder-root),
  .editor :global(.public-DraftEditor-content) {
    margin: 0;
    padding: 9px;
    padding-top: 0;
  }

  .editorWarning>div:first-of-type {
    border-bottom: 2px solid rgba(255, 0, 0, 1);
  }

  .noEditable {
    color: var(--gris-4, #e00505);

    >div {
      background: #F4F4F4;
    }
  }

  .descriptionMultiLanContent {
    display: flex;
    justify-content: space-around;
    margin-top: 16px;
    line-height: normal;
    width: 100%;

    .languageControl {
      height: 36px;

      button {
        width: 47px;
        border: none;

        &:first-of-type {
          border-radius: 5px 0px 0px 5px;
        }

        &:last-of-type {
          border-radius: 0px 5px 5px 0px;
        }
      }
    }

    .description {
      margin: auto 16px;
      width: 100%;
    }

  }

  .descriptionEditorToobar {
    button {
      border: none;
      background: rgba(0, 0, 0, 0);
    }
  }

  .warning {
    font-family: 'Roboto';
    font-size: 15px;
    color: rgba(255, 0, 0, 1);
    margin-top: 8px;
    align-items: center;

    span {
      padding-left: 8px;
    }
  }
}

.errorContainer {
  display: inline;

  .errorIcon {
    vertical-align: bottom;
    color: red;
    margin-left: 5px;
  }

  .errorText {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: red;
    display: inline;
    margin-left: 5px;
  }
}

.descriptionEditorArea {
  .public-DraftEditor-content{
    padding-top: 0 !important;
  }
}