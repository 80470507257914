.boxError {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 600px;
  background: white;
  text-align: center;
  border-radius: 1em;
  font-family: 'Bitter';
  font-style: normal;
  padding-left: 5em;
  padding-right: 5em;

  img {
    width: 500px;
    height: 500px;
  }
}

.titleError {
  font-weight: bold;
  margin-top: -2em;
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  /* or 104% */
  text-align: center;
  /* Negro */
  color: #111111;

}

.descriptionError {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
}

.detail {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  margin-top: 20px;
}


@media (max-width: 1500px) {
  .boxError {
    width: 450px;
    height: 450px;
    top: 55%;

    img {
      width: 300px;
      height: 300px;
    }
  }

  .titleError {
    margin-top: -1em;
  }
}