.usersTable{
  padding: 0 30px;
	background-color: #FFFFFF;
}
.tableHead th {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
  color: #696969;
}
.tableHead th:nth-child(1) {
  width: 615px;
}
.tableHead {
  height: 70px;
}

@media (max-width: 1600px) {
	.tableHead th:nth-child(1) {
    width: 300px;
  }
}
