.budgetContainer {
  width: 95%;
  margin: 16px auto;

  .currencyWrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 40px;

    .noTaxes {
      color: #7A7A7A;
      font-size: 14px;
      font-family: 'Roboto';
    }
  }

  .heading {
    font-family: 'Bitter';
    font-size: 20px;
    font-weight: 700;
    color: #111111;

    .localCurrency {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 400;
      margin-left: 7px;
    }
  }

  .boxContainer {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}

.noTaxes {
  color: #7A7A7A;
  font-size: 14px;
  font-family: 'Roboto';
}