.slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.slick-slider {
    margin-top: 38px;
}

.boxSlide {
    height: 247px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    max-width: 450px;

    img {
        max-height: 100%;
    }
}

.slideHide {
    transform: scale(0.7);
    border-radius: 15px;
    transition: transform 300ms;
    z-index: 0;
    position: relative;
    border-radius: 15px;
    position: relative;
    min-width: 590px;
    border-radius: 15px;
    overflow: hidden;
    max-width: 590px;
    display: none !important;

    img {
        max-width: 590px;
    }

    &.noShow {
        visibility: hidden;
        display: none !important;
    }
}

.slideHide2 {
    transform: scale(0.5);
    visibility: hidden;
    display: none;
}


.activeSlide {
    transform: scale(1);
    z-index: 3;
    position: relative;
    min-width: 436px;
    background-color: black !important;
    margin-top: 0;
    cursor: pointer;

    img {
        max-width: 436px;
    }
}

.slide2 {
    transform: scale(0.8);
    z-index: 2;
    position: relative;
    min-width: 32vw;
    border-radius: 15px;
    background-color: black !important;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 4px 0 #0000001A;

}

.slideSmall {
    transform: scale(0.7);
    z-index: 1;
    position: relative;
    min-width: 29vw;
    border-radius: 15px;
    background-color: black !important;
    overflow: hidden;
    box-shadow: 0 0 4px 0 #0000001A;
    cursor: pointer;

}

*:focus {
    outline: 0;
    outline: none;
}

.slick-prev:before {
    content: url('../../../../assets/carousel/leftArrow.svg');
    color: black;
    font-size: 30px;
}

.slick-next:before {
    content: url('../../../../assets/carousel/rigthArrow.svg');
    color: black;
    font-size: 30px;
}

.dialog-paper {
    overflow: inherit !important;
}

.buttons-current-slide {

    .MuiButtonBase-root.Mui-disabled {
        background-color: rgba(255, 0, 0, 0.25);
        color: white;
    }

}


@media (max-width: 1399px) {
    .fullScreenContent {
        height: 79vh;

        .imageContainer {
            height: 61vh;
        }
    }

    .activeSlide {
        min-width: 290px;

        img {
            max-width: 290px;
        }
    }

    .boxSlide {
        height: 163px;
        max-width: 290px;

        img {
            max-height: 100%;
        }
    }

    .slideSmall {
        min-width: 26vw;
    }

    .slide2 {
        min-width: 29vw;
    }
}