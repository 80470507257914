.locationInfo {
	width: 100%;
	margin-bottom: 40px;
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap:24px;
}

.boxContainer {
	width: calc(25% - 18px);
	min-width: fit-content;
	max-width: 600px;
	min-height: fit-content;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
}

.section {
	margin-bottom: 56px; 
}

.halvedSection {
	width: 100%;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	flex-wrap: wrap;

	.section {
		width: 49%;
		min-width: 580px;
	}
}

.heading {
	font-family: 'Bitter';
	font-size: 20px;
	font-weight: 700;
}

.otherTownsContainer {
	margin-top: 16px;
}

.protectedSpaceContainer {
	margin-top: 16px;
}



.extinctionSpeciesProtectionZonesContainer {
	width: 100%;
	margin-top: 16px;
	border-collapse: separate;
	border: none;


	tr td {
		&:first-of-type {
			float: left;
		}

		&:last-of-type {
			float: right;
		}
	}



	.speciesName {
		width: 55%;
	}

	.dangerLevel {
		width: 43%;
	}

}

.noDataPic {
	text-align: center;
	margin-top: 16px;
	img{
		width: 232px;
		height: 180px;
	}
	p {
		padding-top: 10px;
		width: 400px;
		margin: 16px auto;
	}
}

@media (max-width: 1300px) {
	.halvedSection {
		.section {
			flex-flow: column;
			width: 100%;
		}
	}
}