.dataBimContainer {
    height: 40%;
    min-height: 290px;


    .box {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        gap: 24px;

        .boxContainerLeft {
            width: calc(33% - 12px);
            display: flex;
            height: 100%;
            justify-content: space-between;
            gap: 24px;

            >div {
                width: 50%;
            }

        }

        .boxContainerRight {
            width: calc(67% - 12px);
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            gap: 16px;



            .upperBoxes,
            .lowerBoxes {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: flex-start;
                gap: 24px;
            }

            .upperBoxes {
                height: 39%;

                >div {
                    width: 50%;
                }
            }

            .lowerBoxes {
                height: 56%;

                >div {
                    width: 33%;
                }
            }
        }
    }
}