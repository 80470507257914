.home {
    height: 100vh;
    width: 100%;
    flex-direction: column;
    display: flex;

    .content {
        height: 100%;
        width: 100%;
    }

    .header {
        height: fit-content;
        margin-bottom: 12px;
        display: flex;
        align-items: flex-end;

        >div {
            flex: 1;
            display: flex;
            justify-content: flex-end;

            &:first-child {
                justify-content: flex-start;
            }
        }
    }
}