:root {
  --margin-right: 12px;
}
@-moz-document url-prefix() {
  :root {
    --margin-right: 0;
  }
}
.tabMenuContainer .tabMenu,
.obraInfo {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  padding-bottom: 20px;

  .obraInfoData,
  .obraInfoDataAuth,
  .obraInfoSaveAndSend {
    display: flex;
    flex-flow: row;
  }
}
.goBack {
  padding: 7px 0;
  color: #C0C0C0;
  cursor: pointer;
}
.goBack:hover {
  color: #ff0000;
}
.goBack:active {
  color: #bc0000;
}
.idAndName {
  font-size: 30px;
  font-weight: 700;
  font-family: 'Bitter';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
