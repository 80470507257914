.grid {
  height: 90%;
  margin-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  overflow-y: auto;
}

.cell {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #111111;
  cursor: pointer;
}

.cellRight {
  @extend .cell;
  text-align: right;
}

.cell:active {
  border: none;
}

.cellBold {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #111111;
  cursor: pointer;
}

.cellHeader {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7A7A7A;
  background-color: white;
  cursor: pointer;
}

.cellHeader:hover {
  color: red;
  cursor: pointer;
}

.dataListError {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 4em;
  text-align: center;
  z-index: 10;
  align-items: center;
  justify-content: center;
}

.resultsNumber {
  display: flex;
  flex-flow: row;
  margin-bottom: 15px;
}

.showNumber {
  min-width: 190px;
  padding-top: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #C0C0C0;
  b {
    font-weight: 700;
    color: #696969;
  }
}

.tagsOverlay {
  width: 100%;
}

.filterNotFound {
  position: absolute;
  top: calc(50% + 250px);
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 600px;
  background: white;
  text-align: center;
  border-radius: 1em;
  font-family: 'Bitter';
  font-style: normal;
  padding-left: 5em;
  padding-right: 5em;

  img {
    width: 250px;
    height: 250px;
    margin-left: -30px;
  }

  .titleError {
    margin-top: 1em;
    font-family: 'Bitter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #111111;
  }

  .titleError2 {
    font-family: 'Bitter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #111111;
  }

  .descriptionError {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #111111;
  }

}