:root {
  --margin-right: 12px;
}

@-moz-document url-prefix() {
  :root {
    --margin-right: 0;
  }
}

.tabMenuContainer .tabMenu {
  padding-bottom: 10px;
}
.obraInfo {
  padding-bottom: 0;
}

.tabMenuContainer .tabMenu,
.obraInfo {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 95%;
  margin: auto;

  .obraInfoData,
  .obraInfoDataAuth,
  .obraInfoSaveAndSend {
    display: flex;
    flex-flow: row;
  }

  .obraInfoDataAuth {
    width: calc(100% - 450px);
  }

  .tabMenuWrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    margin-right: var(--margin-right);
  }
}

.tabMenu {
  font-weight: 700;
  font-size: small;
  font-family: 'Roboto';

  .tabMenuOption,
  .tabMenuOption_disabled {
    max-height: 48px;

    button {
      text-transform: none;
      opacity: inherit;
      white-space: inherit;
      font-weight: 700;
      font-size: 14px;
      font-family: 'Roboto';
      padding: 0;
      min-width: 0;
    }
  }

  .tabMenuOption {
    color: #ff0000;

    button {
      cursor: pointer;
      padding: 0;
      min-width: 0;

      &:hover {
        color: #df0202;
      }

      &:active,
      &:focus {
        color: #bc0000;
        background-color: transparent;
      }
    }
  }

  .tabMenuOption_disabled {
    color: #000000;
    cursor: default;
  }
}

.obraInfo div {
  height: fit-content;
}

.goBack {
  padding: 7px 0;
  color: #C0C0C0;
  cursor: pointer;
}

.goBack:hover {
  color: #ff0000;
}

.goBack:active {
  color: #bc0000;
}

.idAndName {
  font-size: 30px;
  font-weight: 700;
  font-family: 'Bitter';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status {
  background-color: #1ABC9C40;
  color: #1ABC9C;
  border-radius: 30px;
  padding: 9px 17px;
  margin-left: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}
.downloadPDF {
  margin-left: 1rem;
  .buttonPDF {
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    &:hover {
      background-color: #ffffff;
      color: #BC0000;
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }
  }
}


@media (min-width: 1500px) {
  .tabMenu {
    :global(.MuiTabs-flexContainer) {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }

  }
}

@media (max-width: 1500px) {
  .tabMenu {


    .tabMenuOption,
    .tabMenuOption_disabled {
      margin: 0 10px;
      max-height: 48px;
    }

    .tabMenuWrapper {
      margin-left: -20px;
      align-items: end;
      :global(.MuiTabs-scrollButtons.Mui-disabled) {
        opacity: 0.1;
      }

      :global(.MuiTabs-scrollButtons) {
        bottom: 3px;
      }

      :global(.MuiTouchRipple-root) {
        display: none;
      }

      :global(.MuiButtonBase-root:active) {
        background-color: transparent;
      }

    }
  }
}

.saveAndSend {
  display: flex;
}