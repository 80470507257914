.clientContainer {
	font-family: 'Roboto';
	width: 95%;
	margin: auto;
	margin-bottom: 30px;

	.heading {
		font-family: 'Bitter';
		font-size: 20px;
		font-weight: 700;
		color: #111111;
	}

	.box {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-flow: row;
		align-items: flex-start;
		margin-top: 16px;
		margin-bottom: 56px;
	}

	.boxContainer {
		width: 50%;
		height: calc(50% - 30px);
		float: left;
		display: flex;
		flex-flow: column;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-right: 30px;
	}


	.boxContainerChild,
	.stateBox {
		img {
			margin-bottom: 3px;
		}
	}

	.boxContainerChild {
		float: left;
	}

	.stateBox {
		width: 100%;
	}
}