.advancedFilters {
  display: flex;
  flex-flow: row;
  position: relative;
  .title {
    margin-right: 55px;
    color: #FF0000;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.5 px;
  }
  .appliedFiltersWrapper {
    position: absolute;
    right: 30px;
    top: 3px;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: #F5B6B6;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 700;
    line-height: 13px;
    color: #FF0000;
  }
}
