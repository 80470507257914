.datesContainer {
	width: 95%;
  margin: auto;
  .heading {
		font-family: 'Bitter';
		font-size: 20px;
		font-weight: 700;
		color: #111111;
  }
  .datesStart, .datesDeadlines, .datesFinish {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 56px;
    margin-top: 16px;
    justify-content: flex-start;
    column-gap: 24px;
  }
  .datesStart > *, .datesDeadlines > * {
    min-width: 250px;
    width: calc(25% - 18px);
  }
  .datesFinish > * {
    width: calc(25% - 18px);
    min-width: 250px;
  }
  .space {
    width: 21%;
    min-width: 250px;
  }
}