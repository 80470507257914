@-moz-document url-prefix() {
    .tdFlag {
        position: relative;
        .span {
            position: absolute;
            left: 2px;
            top: 1px;
        }
    }
}
.box {
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 0;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title,
    .value {
        overflow-wrap: break-word;
        width: 100%;
        padding: 7%;
    }
    .title {
        color: #696969;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 3px;
        flex-basis: 70%;
        padding-right: 0;
    }
    .value {
        color: #111111;
        text-align: right;
        flex-basis: 30%;
        font-family: Bitter;
        font-size: 22px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0px;
    }
    .field {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        height: 65%;
    }
    .bar {
        height: 35%;
        padding: 0 5%;
    }
}