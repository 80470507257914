.box {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 5px 28px 0 28px;
  text-align: left;
  min-height: 268px;


  .boxContainer {
    width: 100%;
    height: 100%;
    margin-top: 6px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .field {
    width: 50%;
    margin: 2% auto;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .picture {
    height: 50px;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .title,
  .value {
    inline-size: 18vw;
    overflow-wrap: break-word;
  }

  .title {
    font-family: 'Roboto';
    color: #696969;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 3px;
  }

  .value {
    font-family: 'Roboto';
    color: #111111;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 38px;
  }
}

@media (max-width: 1500px) {
  .box {
    min-height: 293px;
  }
}