.selectorContainer {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}

.selector {
    margin: 10px 12px 0px 12px;
    width: -webkit-fill-available;
    width: -moz-available;
    text-align: left;
    color: #696969;
}