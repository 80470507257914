.selectorContainer {
  width: 100%;
  text-align: center;
}
.selectorGroupAndType {
  margin: 10px 12px 0px 12px;
  width: -webkit-fill-available;
  width: -moz-available;
  text-align: left;
  color: #696969;
}
.item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.checkbox {
  accent-color: #FF0000;
}

::-webkit-scrollbar {
  width: 12px;
  height: 42px;
}

::-webkit-scrollbar-track {
  background: #D9D9D9;
  box-shadow: inset 0px 0px 0px 4px white; 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: #FF0000; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #DF0202; 
}

::-webkit-scrollbar-thumb:active {
  background: #BC0000; 
}