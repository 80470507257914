.otherTownsContainer {
  margin-top: 16px;
  width: 100%;
  .additionalTown {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 24px;
    .town {
      width: 53%;
    }
    .zipcode {
      width: 41%;
    }
    .deleteBtn {
      border-radius: 8px;
      border: none;
      background-color: #696969;
      height: 60px;
      width: 60px;
    }
    .deleteBtn:hover {
      cursor: pointer;
      background-color: #414141
    }
    .deleteBtn:active{
      background-color: #111111;
    }
  }
}

.noDataPic {
  padding: 40px 0;
  text-align: center;

  p {
		padding-top: 10px;
		width: 400px;
		margin: 16px auto;
	}
}