.server-error {
  position: relative;
  width: 697px;
  height: 475px;
  margin-top: -15%;

  background: #FFFFFF;
  border-radius: 20px;

  .server-error-content {
    margin: 50px 0;

    svg {
      width: auto;
      height: auto;
      margin-bottom: 32px;
    }

    .title {
      font-family: 'Bitter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 25px;
      text-align: center;
    }

    .subtitle {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
    }
  }
}