.notificationContainer {
    margin-right: 20px;

    .notificationButton {
        border-radius: 50px;
        background-color: rgba(239, 237, 237, 1);
        height: 35px;
        width: 35px;
    }


}

.menuList {
    padding: 0;
    max-width: 350px;
    min-width: 200px;
    overflow-y: hidden;
    overflow-x: hidden;

    .menuHeader {
        display: flex;
        justify-content: space-between;
        padding: 2% 3% !important;

        &:hover {
            background-color: #fff;
        }

        .headerTitle {
            font-family: Bitter;
            font-size: 16px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0px;
            color: rgba(17, 17, 17, 1);
            padding-left: 10px;

        }

        .all {
            font-family: Roboto;
            font-size: 13px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0px;
            color: rgba(255, 0, 0, 1);

            &:hover {
                color: #DF0202;
            }

            &:active {
                color: #BC0000;
            }
        }
    }

    .notificationList {
        max-height: 280px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .notificationItem {
        display: flex;
        flex-direction: row;
        padding: 4% 2%;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 0, 0, 0.15);
        }

        .notificationPoint {
            width: 7%;
            margin-right: 2%;
            min-width: 24px;
        }

        .notificationData {

            display: flex;
            flex-direction: column;

            .notificationName {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 0.5px;
                color: rgba(17, 17, 17, 1);


            }

            .notificationMotive {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.5px;
                color: rgba(17, 17, 17, 1);
                margin: 5px 0;
            }

            .notificationDate {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 0.5px;
                color: rgba(134, 154, 180, 1);


            }
        }
    }

    .noData {
        padding: 5% 6% !important;
        min-width: 250px;
    }
}