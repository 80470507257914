.MuiPickersPopper-root {
        top: auto;
        bottom: calc(100% + 8px) !important;
        left: 0 !important;

    .MuiPickersYear-yearButton.Mui-selected,
    .MuiPickersMonth-monthButton.Mui-selected,
    .MuiPickersDay-root.Mui-selected {
        background-color: rgba(245, 182, 182, 1) !important;
    }
}