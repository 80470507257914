.header {
    display: flex;
    padding: 1rem 2rem 1rem 4rem;
    font-family: Bitter;
    font-size: 32px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    color: rgba(17, 17, 17, 1);


    .goBack {
        padding-right: 10px;
        cursor: pointer;

        svg {
            color: rgba(192, 192, 192, 1);
        }
    }
}
.error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    >div {
        display: flex;
        justify-content: center;
    }
}

.pendingActionsContent {
    display: flex;
    overflow-y: auto;

    .filtersContainer {
        width: 23%;
        padding: 3rem 1rem 1rem 4rem;

        .filtersTitle {
            font-family: Bitter;
            font-size: 28px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0px;
            color: rgba(17, 17, 17, 1);
        }

        .filtersList {
            .filtersItem {
                display: flex;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.5px;
                color: rgba(17, 17, 17, 1);
                padding-left: 0;
                padding-bottom: 0;

                &:hover {
                    color: rgba(0, 0, 0, 1);
                    background-color: rgba(0, 0, 0, 0);
                }

                svg {
                    width: 14.17px;
                    height: 16.67px;
                    padding-right: 15px;
                }

                &.filterSelected {
                    color: rgba(255, 0, 0, 1);

                    &:hover {
                        color: rgba(223, 2, 2, 1)
                    }

                    svg {
                        color: rgba(255, 0, 0, 1)
                    }
                }

            }
        }

    }

    .resultsContainer {
        width: 77%;
        padding-right: 3rem;
        display: flex;
        flex-direction: column;
        align-content: flex-end;
        box-shadow: 0;

        .resultsHeader {
            padding-top: 3rem;
            border-bottom: solid 1px rgba(196, 196, 196, 1);
            z-index: 10;
            display: flex;
            justify-content: end;

            label {
                span {
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.5px;
                }
            }

        }
    }

    .resultsList {
        flex-basis: 100%;


        .notificationItem {
            display: flex;
            flex-direction: column;

            .notificationHeader {
                display: flex;
                flex-direction: row;


                padding: 2% 0 2% 1%;

                &:hover {
                    background-color: rgba(0, 0, 0, 0);
                }

                .notificationPoint {
                    width: 4%;
                    margin-right: 2%;
                    min-width: 24px;
                }

                .notificationDataCTI {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-basis: 100%;


                    .notificationData {

                        display: flex;
                        flex-direction: column;

                        .notificationName {
                            font-family: Roboto;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 14px;
                            letter-spacing: 0.5px;
                            color: rgba(17, 17, 17, 1);


                        }

                        .notificationMotive {
                            font-family: Roboto;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 14px;
                            letter-spacing: 0.5px;
                            color: rgba(17, 17, 17, 1);

                        }

                        .notificationDate {
                            font-family: Roboto;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 14px;
                            letter-spacing: 0.5px;
                            color: rgba(134, 154, 180, 1);


                        }
                    }

                    .notificationCTI {
                        button {
                            background-color: rgba(255, 0, 0, 1);
                            font-family: Roboto;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 14px;
                            letter-spacing: 0.5px;
                            border-radius: 50px;
                            height: 46px;
                            padding: 10px 20px;

                            &:disabled {
                                background-color: rgba(245, 182, 182, 0.4);
                                color: rgba(255, 0, 0, 0.4);
                            }
                        }

                    }
                }
            }

            .notificationComment {
                padding-left: 6%;
            }
        }

        .noResults {
            margin-top: 6%;
        }
    }
}