.worktypesTableRow {
  vertical-align: top;

  td:nth-child(1) {
    padding-right: 21px;
    width: 40%;
  }

  td:nth-child(2) {
    width: 60%;
  }

  td:nth-child(3) {
    width: 40%;
  }

  .titleValue3 {
    width: 58%;
  }

  .titleValue4 {
    width: 40%;
  }

  .trashBtn {
    margin-left: 20px;
  }
}

.workTypes,
.magnitudes {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 24px;
}