.simple-select {
    height: 59px;
    min-width: 150px;

    .MuiPaper-root {
        .MuiList-root {
            .Mui-selected {
                background-color: rgba(255, 0, 0, 0.05);
            }

            .MuiMenuItem-root {
                padding: 0;


                .menu-item {
                    padding: 6px 16px;
                    width: 100%;
                    display: flex;

                    &:hover {
                        background-color: rgba(255, 0, 0, 0.1);
                    }
                }
            }
        }
    }

    div.MuiOutlinedInput-root {
        width: 100%;
        height: 60.5px;
        margin-top: -19px;

        .MuiSelect-outlined {
            z-index: 1;
            margin-bottom: -17px;
            font-size: 16px;
            font-weight: 700;

            >div {
                color: rgba(0, 0, 0, 1);

                span {
                    white-space: pre-line;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }

        .MuiSvgIcon-root {
            z-index: 1;
        }

        fieldset {
            background-color: #fff;
            border: none;
            border-radius: 5px 5px 0 0;
        }


        &.Mui-disabled {
            margin-top: -4px;

            fieldset {
                background-color: rgba(244, 244, 244, 1);
                border: none;
                border-bottom: 1px solid #c0c0c0;
                border-radius: 5px 5px 0 0;
            }

            .MuiSvgIcon-root {
                z-index: 0;
            }
        }

        &.Mui-focused {
            fieldset {
                border: none;
                border-bottom: 1px solid #7A7A7A;
            }
        }
    }

    .MuiFormLabel-root {
        top: 10px;
        z-index: 1;
        margin-left: 15px;
        height: 18px;
        font-size: 14px;
    }

}

.simple-select-contrast {
    div.MuiOutlinedInput-root {
        fieldset {
            background-color:rgba(245, 252, 255, 1);
            border: none;
            border-radius: 5px 5px 0 0;
        }
    }
}