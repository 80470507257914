.advancedSearchContainer {
	overflow: auto;
	overflow-x: hidden;
	position: absolute;
	bottom: 0px;
	top: 156px;
	width: 100%;
	.filtersList {
		width: 95%;
		margin: 30px auto;
		.section {
			margin-bottom: 20px;
		}
	}
}