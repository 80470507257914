.container-mapSwitchList {
  position: relative;
  text-align: right;
  max-width: 250px;
  right: 4em;

  .switch-button-map {
    border-top-left-radius: 30px 30px;
    border-bottom-left-radius: 30px 30px;
    cursor:pointer;
    &:hover {
      color: #FF0000;
      background-color: #FF00000D;
      border: 1px solid red;
    }

    &:active {
      color: #BC0000;
      background-color: #FF00001A;
    }
    
  }
  .switch-button-list {
    border-top-right-radius: 30px 30px;
    border-bottom-right-radius: 30px 30px;
    cursor:pointer;
    &:hover {
      color: #FF0000;
      background-color: #FF00000D;
      border: 1px solid red;
    }

    &:active {
      color: #BC0000;
      background-color: #FF00001A;
    }
  }

  button {
    padding: 10px 20px;
    background-color: white;
    color: #bbbbbb;
    border: 1px solid #bbbbbb;
    cursor: pointer;
  }

  button:disabled {
    color: red;
    border: 1px solid red;
    font-weight: 600;
    cursor: default;
  }
  
}