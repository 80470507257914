.MuiPaper-root {
    .MuiList-root {
        .Mui-selected {
            background-color: rgba(255, 0, 0, 0.05);
        }

        .MuiMenuItem-root {
            padding: 0;


            .menu-item {
                padding: 6px 16px;
                width: 100%;
                display: flex;

                &:hover {
                    background-color: rgba(255, 0, 0, 0.1);
                }
            }
        }
    }
}