

.box {
  height: 150px;
  background-color:#FFFF;
  border-radius: 20px;
  padding: 30px 31px;
  margin-bottom: 5%;
  text-align: left;
  position: relative;
  
  .field {
    height: 100%;
    
  }
  .picture{
    margin-bottom: 10px;
  }
  .content{
    width: 100%;
		margin: 2% auto;
		display: flex;
		flex-flow: row;
		flex-wrap: nowrap;
		justify-content: space-between;
  }
  .title, .value {
    inline-size: 15vw;
    overflow-wrap: break-word;
  }
  .title {
    color: #111111;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 700;
    line-height: 17.5px;
    margin-bottom: 10px;
  }
  .value{
    color: #111111;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 17.5px;
    margin-bottom: 5px;
  }
  .participationTitle{
    color: #7A7A7A;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    line-height: 17.5px;
    margin-bottom: 5px;
  }
  .participationValue{
    color: #111111;
    font-family: 'Bitter';
    font-size: 32px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 5px;
  }
 
}