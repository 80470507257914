.sectionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  margin-bottom: 38px;
}

.dapClient {
  width: calc(25% - 12px);
  margin-top: 14px;

  :global(#dapNo-value) {
    color: red !important;
  }

  :global(#dap-value) {
    color: #1ABC9C !important;
  }

  :global(#dapEmpty-value) {
    color: black;
  }
}

.dapAcciona {
  width: calc(25% - 12px);
  margin-top: 14px;

  span {
    color: #1ABC9C;
  }
}

.dapAccionaNo {
  width: calc(25% - 12px);
  margin-top: 14px;

  span {
    color: red;
  }
}

.dapAccionaEmpty {
  width: calc(25% - 12px);
  margin-top: 14px;

  span {
    color: black;
  }
}