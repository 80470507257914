.dialogContent {
  text-align: center;
  padding: 2em;
  margin: 0 auto;
  .header {
    font-family: Bitter;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  }
  .text {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    padding-bottom: 2em;
  }
  .header, .text {
    letter-spacing: 0px;
    text-align: center;
    color: #111111;
  }
  .userInput {
    background-color: #F7F8FA;
    border-radius: 8px 8px 0 0;
    margin-bottom: 2em;
    padding: 10px 20px;
    border-bottom: 1px solid #111111;
    position: relative;
    .userInputTitle {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 12px;
      color: #7A7A7A;
      text-align: left;
    }
    .inputField {
      border: none;
      background: none;
      width: 100%;
      color: #111111;
      font-family: Roboto;
      font-weight: 700;
      font-size: 15px;
    }
    .searchIcon {
      position: absolute;
      right: 1em;
      top: 20px;
    }
  }
  .filteredUsers{
    overflow-y: scroll;
    margin-bottom: 2em;
    max-height: 20em;
    table {
      width: 90%;
      margin: auto;
      border-collapse: collapse;
      tr {
        height: 5em;
        border-bottom: 1px solid #E5E5E5;
        td {
          border-bottom: 1px solid #E5E5E5;
        }
        td:nth-child(1) {
          text-align: left;
        }
        td:nth-child(2) {
          font-family: 'Roboto';
          font-weight: 700;
          font-size: 16px;
        }
        .addProjectContainer {
          display: flex;
          flex-flow: row;
          justify-content: right;
          .isIncluded {
            color: rgba(255, 0, 0, 0.25);
            cursor: default;
          }
          .notIncluded {
            color: #FF0000;
          }
          .notIncluded:hover {
            color: #DF0202;
            cursor: pointer;
          }
          .notIncluded:active {
            color: #BC0000;
          }
        }
      }
    }
  }
  .buttonClose {
    background-color: rgba(255, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
    padding: 1em 5em;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    border-radius: 5px;
    &:hover {
        background-color: #DF0202;
        cursor: pointer;
    }
    &:active {
        background: #BC0000;
    }
  }
}
