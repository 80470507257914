:root {
  --margin-right: 12px;
}
@-moz-document url-prefix() {
  :root {
    --margin-right: 0;
  }
}
.flexrow {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}
.tabMenuContainer {
  @extend .flexrow;
  width: 95%;
  margin: auto;
  padding-bottom: 20px;
  .goBack {
    padding: 7px 0;
    color: #C0C0C0;
    cursor: pointer;
  }
  .goBack:hover {
    color: #ff0000;
  }
  .goBack:active {
    color: #bc0000;
  }
  .idAndName {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Bitter';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .filterHeaderOptions {
    @extend .flexrow;
    align-items: center;
    margin-left: auto;
    %custom_font {
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 11px;
      line-height: 25px;
    }
    .cleanFiltersButton {
      @extend %custom_font;
      color: #FF0000;
      margin-right: 20px;
    }
    .cleanFiltersButton:hover {
      color: #df0202;
      cursor: pointer;
    }
    .cleanFiltersButton:active {
      color: #bc0000;
    }
    .showResultsButton {
      background-color: #FF0000;
      border-radius: 5px;
      padding: 10px 20px;
      @extend %custom_font;
      color: #FFFFFF;
    }
    .showResultsButton:hover {
      background-color: #df0202;
      cursor: pointer;
    }
    .showResultsButton:active {
      background-color: #bc0000;
    }
  }
}