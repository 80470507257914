.containerGroup, .containerGroupWithDelete {
  vertical-align: top;
  td:nth-child(1) {
    padding-right: 12px;
    width: 25%;
  }
  td:nth-child(2) {
    padding-left: 12px;
    padding-right: 12px;
    width: 25%;
  }
  td:nth-child(3) {
    padding-left: 12px;
    padding-right:12px;
    width: 25%;
  }
  td:nth-child(4) {
    padding-left: 12px;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 24px;
  }
}
.containerGroup {
  .titleValue4 {
    width: 100%;
  }
}
.containerGroupWithDelete {
  .titleValue4 {
    width: 85%;
  }
}