.editionHeading {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: baseline;
  .heading {
    font-family: 'Bitter';
    font-size: 20px;
    font-weight: 700;
  }
}

.errorContainer {
  display: inline;

  .errorIcon {
    vertical-align: bottom;
    color: red;
    margin-left: 5px;
  }

  .errorText {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: red;
    display: inline;
    margin-left: 5px;
  }
}