.addButton {
  color: #FF0000;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.addButton:hover {
  color: #DF0202;
}
.addButton:active {
  color: #BC0000;
}