.adminPanel {
  display: flex;
  align-items: center;
  right: 29em;
  padding-bottom: 5px;
  margin-right: 20px;
  cursor: pointer;

  .adminPanelText {
    color: #7A7A7A;
    margin-left: 10px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    margin-left: 1em;
    margin-top: 3px;
  }

  img {
    display: flex;
    width: 20px;
  }
}