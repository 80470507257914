.workUnit, .workUnitWithDelete {
  //vertical-align: top;
  td:nth-child(1) {
    padding-right: 21px;
    width: 70%;
  }
  td:nth-child(2) {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
.workUnit {
  .titleValue3 {
    margin-top: 3px;
    width: 100%;
  }
}
.workUnitWithDelete {
  .titleValue3 {
    width: 85%;
    margin-right: 24px;
  }
}
