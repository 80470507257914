@-moz-document url-prefix() {
  .tdFlag {
    position: relative;

    .span {
      position: absolute;
      left: 2px;
      top: 1px;
    }
  }
}

.box {
  height: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 12px 16px;
  margin-bottom: 16px;
  text-align: left;
  min-width: 100px;
  table {
    height: 100%;

    tr {
      td {
        vertical-align: middle;
        height: 50px;
      }

      .tdFlag {
        position: relative;

        .flagContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: url("../../../../assets/flagCircle.svg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 50px;
          width: 50px;
          margin-bottom: 4px;
        }
      }
    }
  }

  .picture {
    height: 50px;
    // margin-bottom: 5%;
  }

  .title,
  .value {
    inline-size: 100%;
    overflow-wrap: break-word;
  }

  .title {
    color: #696969;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 3px;
  }

  .value {
    color: #111111;
    font-size: 16px;
    font-weight: 700;
  }
}
