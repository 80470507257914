
.editSpeciesAndZones {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
  .section {
    width: 48%;
    min-width: 580px;
  }
}

@media (max-width: 1350px) {
  .editSpeciesAndZones {
    flex-flow: column;
    .section {
      width: 100%;
    }
  }
}