.worktypesTableRow {
  vertical-align: top;
  td:nth-child(1) {
    padding-right: 12px;
    width: 60%;
  }
  td:nth-child(2) {
    padding-left: 12px;
    width: 40%;
  }
  .titleValue1{
    width: 28%;
  }
  .titleValue2{
    width: 70%;
  }
  .titleValue3{
    width: 58%;
  }
  .titleValue4{
    width: 40%;
  }
}
.workTypes, .magnitudes {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 24px;
}
