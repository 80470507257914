body {
  :global(.MuiPickersYear-yearButton.Mui-selected) {
    background-color: rgba(245, 182, 182, 1) !important;
  }

  :global(.MuiPickersMonth-monthButton.Mui-selected) {
    background-color: rgba(245, 182, 182, 1) !important;
  }

  :global(.MuiPickersDay-root.Mui-selected) {
    background-color: rgba(245, 182, 182, 1) !important;
  }
}

.datePickerContainer {
  display: flex;
  justify-content: space-between;
  height: 18px;
}

.clearDatePickerContainer {
  .clearDatePicker {
    margin-top: -10px;
    text-align: right;
  }
}
