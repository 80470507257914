.root {
  fieldset {
    border-color: 'green',
  }

  .MuiInputBase-root {
    .MuiOutlinedInput-root {
      padding-right: 0;
    }
  }


  .MuiAutocomplete-input,
  .MuiInputLabel-root {
    font-size: 15px;
  }

  .Mui-focused {
    border-color: red;
  }

  // '& label.Mui-focused': {
  //   color: '#000000';
  // };
  // '& .MuiOutlinedInput-root': {
  //   '&.Mui-focused fieldset': {
  //     borderColor: '#000000';
  //     border: '2px solid black';
  //   };
  // };
  .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    color: black;
  }
}

.icon {
  color: #000000;
}