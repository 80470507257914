.projectsTable{
  padding: 0 30px;
	background-color: #FFFFFF;
  .tableHead th {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto';
    color: #696969;
  }
  .tableHead th:nth-child(1) {
    width: 35%;
  }
  .tableHead th:nth-child(2) {
    padding-left: 0;
    width: 25%;
  }
  .tableHead th:nth-child(3) {
    padding-left: 0;
    width: 25%;
  }
  .tableHead th:nth-child(3) {
    width: 50px;
  }
  .tableHead {
    height: 70px;
  }
  .tableRow {
    padding: 20px 0;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    flex-flow: row;
    .nameCell {
      width: 35%;
      margin: auto 0;
    }
    .rolesCell {
      width: 25%;
      .rolesWrapper {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        height: fit-content;
        .viewer, .viewer_vip {
          color: #006FF5;
          background-color: rgba(0, 111, 245, 0.25);
        }
        .editor {
          color: #61BC1A;
          background-color: rgba(216, 238, 198, 1);
        }
        .validator {
          color: #F39C12;
          background-color: rgba(243, 156, 18, 0.25);
        }
        .administrator {
          color: #F93939;
          background-color: rgba(255, 0, 0, 0.25);
        }
        .viewer, .editor, .validator, .administrator, .viewer_vip {
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          padding: 10px 5px;
          margin: 5px 10px 5px 0;
          width: 150px;
          text-align: center;
        }
      }
    }
    .scopeCell {
      width: 25%;
      margin: auto 0;
      .scopeWrapper {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        height: fit-content;
      }
    }
    .managementIcons {
      margin-left: auto; 
      margin-right: 0;
      padding: 15px;
      display: flex;
      flex-flow: row;
      width: fit-content;
      .icon {
        margin-left: 15px;
        color: #7A7A7A;
      }
      .icon:hover {
        cursor: pointer;
        color: #FF0000;
      }
      .icon:active {
        color: #BC0000
      }
    }
    .nameContainer, .scopeWrapper {
      display: flex;
      flex-flow: row;
      color: #111111;
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 16px;
    }
  }
}
.noData {
  margin: auto;
  width: 500px;
  padding: 130px;
  text-align: center;
  .paragraph {
    margin-top: 20px;
    font-family: 'Bitter';
    font-weight: bolder;
    font-size: 24px;
  }
}
.dialog {
  min-width: 750px !important;
  min-height: 300px;
}
.dialogContent {
  max-width: 700px;
  min-width: 485px;
  text-align: center;
  padding: 4em;
  margin: 0 auto;
}

.details{
  text-align: left;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;

  p {
    margin-top: 4px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.buttonCancel {
  margin-top: 2em !important;
  color: rgba(255, 0, 0, 1) !important;
  border-color: rgba(255, 0, 0, 1) !important;
  text-transform: none !important;
  min-width: 150px !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 30px !important;

  &.apply {
    color: #FFFFFF;
    border-radius: 5px;
  }

  &:hover {
    background: none !important;
    color: rgba(223, 2, 2, 1) !important;
    border-color: rgba(223, 2, 2, 1) !important;
  }

  &:active {
    background: #BC0000;
  }

  &.close {
    color: #111111;
    background-color: rgba(0, 0, 0, 0);
  }
}
.buttonDelete{
  margin-top: 2em !important;
  background: rgba(105, 105, 105, 1) !important;
  text-transform: none !important;
  color: #FFFFFF !important;
  min-width: 150px !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 30px !important;

  &.apply {
    color: #FFFFFF;
    border-radius: 5px;
  }

  &:hover {
    background-color: rgba(65, 65, 65, 1) !important;
  }

  &:active {
    background-color: #111111;
  }

  &.close {
    color: #111111;
    background-color: rgba(0, 0, 0, 0);
  }
}
.titleDelete {
  font-family: Bitter;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 20px;
  max-width: 550px;
}
.dialogContentError{
	max-width: 697px;
	min-width: 450px;
	text-align: center;
	padding-top: 6em;
	padding-bottom: 4em;
	margin: 0 auto;
	.buttonConfirm {
	  background: rgba(255, 0, 0, 1);
	  text-transform: none;
	  color: #FFFFFF;
	  border-color: rgba(255, 0, 0, 1);
	  min-width: 150px;
	  font-family: Roboto;
	  font-size: 12px;
	  font-weight: 700;
	  line-height: 30px;
  
	  &.apply {
		color: #FFFFFF;
		border-radius: 5px;
	  }
  
	  &:hover {
		background-color: rgba(223, 2, 2, 1);
	  }
  
	  &:active {
		background-color: rgba(188, 0, 0, 1);
	  }
  
	  &.close {
		color: #FFFFFF;
		background-color: rgba(188, 0, 0, 1);
	  }
	}
	.serverError {
	  position: relative;
	  width: 697px;
	  margin-top: -15%;
  
	  background: #FFFFFF;
	  border-radius: 20px;
	
	  .serverErrorContent {
      margin: 50px 0;
    
      svg {
        width: auto;
        height: auto;
        margin-bottom: 32px;
        margin-top: 1em;
      }
    
      .title {
        font-family: 'Bitter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 0.5em;
      }
	
      .subtitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        margin-bottom: -1em;
      }
	  }
	}
}