.ag-theme-material {
  --ag-row-hover-color: rgba(255, 0, 0, 0);
  --ag-header-active-color: rgba(255, 0, 0, 0.1);
  --ag-header-cell-hover-background-color: none;
  --ag-row-height: calc(var(--ag-grid-size) * 8);
}

.ag-row:hover {
  background-color: rgba(255, 0, 0, 0.05);
}

.ag-row:active {
  background-color: rgba(255, 0, 0, 0.1);
}

.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #D9D9D9;
  background: #D9D9D9;
  border-radius: 14px;
  border: 2px solid #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 18px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.ag-tooltip {
  background-color: #707070;
  color: #FFFFFF;
  border-radius: 5px;
  padding: '0.625rem';
}


.ag-header-cell.right {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7A7A7A;
  background-color: white;
  cursor: pointer;
  div {
    justify-content: right;
  }
}

.ag-header-cell.right:hover {
  color: red;
  cursor: pointer;
}