.autocompleteContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  gap: 15px;

  .titleValue1 {
    width: 400px;
  }

  .titleValue1 {
    :global(.MuiInput-root) {
      height: 25px;
      align-items: baseline;
      input {
        padding: 0px;
      }
      .cancelIcon {
        color: #AAAAAA;
      }
      .cancelIcon:hover {
        color: #7A7A7A;
        cursor: pointer;
      }
      .cancelIcon:active {
        color: #353535;
      }
    }
  }
}