.container {
  margin: auto;
}

.heading {
  font-family: 'Bitter';
  font-size: 20px;
  font-weight: 700;
  color: #111111;

  .info {
    vertical-align: bottom;
    color: #696969;
    margin-left: 5px;
  }
}

.containerDocumentation {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: left;
}

.containerWebs {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: left;
}

.containerDoc {
  width: calc(33% - 3em - 20px); // 33% less padding and margin
  height: 80px;
  max-width: 472px;
  max-height: 80px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: rgba(229, 229, 229, 1);
  padding: 0px 10px;
  margin-top: 16px;
  margin-bottom: 1em;
  margin-right: 24px;
}

.lineBreak {
  width: 100%;
}

.containerImage {
  border-radius: 40px;
  width: 40px;
  height: 40px;
}

.containerBody {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  flex: 1;
  padding-left: 20px;
  align-items: flex-start;
  justify-content: center;

  a {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #FF0000;
    text-decoration: none;
    display: flex;
  }

  a:link {
    text-decoration: none;
  }


  a:visited {
    text-decoration: none;
  }


  a:hover {
    text-decoration: none;
    color: #DF0202;
    ;
  }


  a:active {
    text-decoration: none;
    color: #BC0000;
  }

  img {
    margin-left: 1em;
  }
}

.containerBody p {
  margin: 2px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(122, 122, 122, 1);

  b {
    color: #111111;
    font-size: 14px;
  }
}

.containerMoreInfo {
  width: calc(33% - 3em - 20px); // 33% less padding and margin
  height: 80px;
  max-width: 472px;
  max-height: 80px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: rgba(229, 229, 229, 1);
  padding: 0px 10px;
  margin-top: 16px;
  margin-bottom: 1em;
  margin-right: 3em;
}

.containerImageMoreInfo {
  border-radius: 40px;
  width: 40px;
  height: 40px;
}

.containerBodyMoreInfo {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  flex: 1;
  padding-left: 20px;
  align-items: flex-start;
  justify-content: center;

  a {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #FF0000;
    text-decoration: none;
    display: flex;
  }

  img {
    margin-left: 1em;
  }
}

.containerBodyMoreInfo p {
  margin: 2px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #111111;
}