/* .MuiSelect-select[aria-expanded="true"] {
    color: rgba(223, 2, 2, 1) !important;
} */

.MuiSelect-select {
    color: rgba(122, 122, 122, 1) !important;
}

.Mui-focused {
    outline: none !important;
}

.MuiButtonBase-root:active {
    background-color: rgba(255, 0, 0, 0.1);
}

/* .MuiPaper-root{
    margin-left: -15px;
} */

.MuiPaper-root {
    border-radius: 13px !important;
    background-color: white !important;
}