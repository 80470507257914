.dialogContent {


    .dialogForm {

        >div {
            padding: 0.5%;

            >div {
                width: 100%;
            }

        }

    }
}

.buttonClose {
    text-transform: none !important;
    background: #FF0000 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    box-shadow: none !important;

    &:hover {
        background: rgba(223, 2, 2, 1) !important;
        box-shadow: none !important;
    }

    &:active {
        background: #BC0000;
    }

    &:disabled {
        background: rgba(255, 0, 0, 0.3) !important;
        color: #fff !important;
        box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.2) !important;


    }

}

.buttonSecondary {
    text-transform: none !important;
    background: rgba(0, 0, 0, 0) !important;
    border: solid 1px #FF0000 !important;
    color: #FF0000 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    box-shadow: none !important;

    &:hover {
        background: none !important;
        color: rgba(223, 2, 2, 1) !important;
        border-color: rgba(223, 2, 2, 1) !important;
    }

    &:active {
        background: #BC0000;
    }

}