.addBtnContainer {
  position: relative;
  .addBtn {
    width: fit-content;
    float:right;
    margin-bottom: 10px;
  }
}
.containerGroup {
  width: 95%;
  margin: auto;
  table {
    width: 100%;
  }
  .noDataPic {
    padding: 40px 0;
    text-align: center;
    img {
      width: 232px;
      height: 180px;
    }
    p {
      padding-top: 10px;
      width: 400px;
      margin: 16px auto;
    }
  }
}