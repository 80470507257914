.certificates {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  gap: 24px;
  margin-top: 16px;

  .width1{
    width: 70%;
  }

  .width2{
    width: calc(30% - 10px);
  }

  .width3{
    width: calc(33% - 10px);
  }
}

.firstElement {
  margin-top: 13px;
}