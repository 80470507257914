.basicFiltersContainer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .collapsableComponent {
        margin-top: 20px;
        width: 30%;
        border-radius: 8px;
        background: #F7F8FA;
        height: fit-content;
        padding: 10px;
    }
}