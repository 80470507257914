@-moz-document url-prefix() {
  .tdFlag {
    position: relative;

    .span {
      position: absolute;
      left: 2px;
      top: 1px;
    }
  }
}

.box {
  height: 100%;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 0 28px;
  text-align: left;
  min-width: 220px;
  min-height: 160px;
  display: flex;
  align-items: center;

  table {
    height: 100%;

    tr {
      td {
        vertical-align: middle;
        height: 50px;

        &:not(:first-of-type) {
          >div {
            height: 80%;
          }
        }
      }

      .tdFlag {
        position: relative;

        span {
          position: absolute;
          left: 12px;
          top: 14px;
        }
      }
    }
  }

  .pictureBox {
    margin-right: 30px;
  }

  .picture {
    height: 85px;
    margin-bottom: 5%;
  }

  .title,
  .value {
    inline-size: 18vw;
    overflow-wrap: break-word;
  }

  td:last-of-type {

    .title,
    .value {
      inline-size: 10vw;
      overflow-wrap: break-word;
    }
  }

  .title {
    color: #696969;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 3px;
  }

  .value {
    color: #111111;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }
}