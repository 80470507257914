.keywordTextField {
  margin: 12px;
  margin-top: 8px;
  text-align: left;
}
.keywordTextField div{
  width: 100%;
}
.keywordTextField input {
  margin-right: 55px;
}
.keywordTextField button {
  font-weight: 700;
  color: #FFFFFF;
  background-color: #FF0000;
  border: 0;
  height: 100%;
  margin-right: 1px;
  position: absolute;
  right: 0;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}
.keywordTextField button:hover {
  background-color: #DF0202;
}
.keywordTextField button:active {
  background-color: #BC0000;
}
.keywordTextField button:disabled {
  background-color: #D9D9D9;
  cursor:default;
}
.keywordTextField input::placeholder {
  color: #000000;
}