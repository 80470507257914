.locationEnvironmentContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 35px;
  }
  
  .collapsableComponent {
    margin-top: 20px;
    border-radius: 8px;
    background: #F7F8FA;
    border-radius: 8px;
    height: fit-content;
    padding: 10px;
  }