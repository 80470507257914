
.rolesWrapper {
  position: relative;
  .viewer, .editor, .validator, .administrator, .viewer_vip, .download {
    position: absolute;
    top: -14px;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 5px;
    margin: 5px 10px 5px 0;
    width: 150px;
    text-align: center;
  }
  .viewer, .viewer_vip {
    color: #006FF5;
    background-color: rgba(0, 111, 245, 0.25);
  }
  .editor {
    color: #61BC1A;
    background-color: rgba(216, 238, 198, 1);
  }
  .validator {
    color: #F39C12;
    background-color: rgba(243, 156, 18, 0.25);
  }
  .administrator {
    color: #F93939;
    background-color: rgba(255, 0, 0, 0.25);
  }
  .download {
    color: rgb(105, 190, 156);
    background-color: #C5EEE6;
  }
}
.collapsableList{
  padding: 0 20px 20px 20px;

  ul, li { list-style: none; margin: 0 0 0 1px; padding: 0; }
  
  li { padding-left: 1em;
    border: 1px solid black;
    border-width: 0 0 1px 1px; 
  }
  
  li.container { border-bottom: 0px; }
  
  li.empty { font-style: italic;
    color: silver;
    border-color: silver;
  }
  
  li table { margin: 0;
    background: white;
    position: relative;
    top: 0.5em; 
  }
  
}
.collapsableListRow {
  width: 100%;
  td:nth-child(1) {
    min-width: 570px;
    padding: 0 20px 0 10px;
    height: 50px;
    vertical-align: bottom;
  }
  td:nth-child(2) {
    width: 80%;
  }
  .managementIcons {
    display: flex;
    flex-flow: row;
    width: fit-content;
    margin-right: 0;
    .icon {
      margin-left: 15px;
      color: #7A7A7A;
    }
    .icon:hover {
      cursor: pointer;
      color: #FF0000;
    }
    .icon:active {
      color: #BC0000
    }
  }
}

.dialog {
  min-width: 750px !important;
  min-height: 300px;
}

.dialogContent {
  max-width: 700px;
  min-width: 485px;
  text-align: center;
  padding: 4em;
  margin: 0 auto;
}

.details{
  text-align: left;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;

  p {
    margin-top: 4px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.buttonCancel {
  margin-top: 2em !important;
  color: rgba(255, 0, 0, 1) !important;
  border-color: rgba(255, 0, 0, 1) !important;
  text-transform: none !important;
  min-width: 150px !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 30px !important;

  &.apply {
    color: #FFFFFF;
    border-radius: 5px;
  }

  &:hover {
    background: none !important;
    color: rgba(223, 2, 2, 1) !important;
    border-color: rgba(223, 2, 2, 1) !important;
  }

  &:active {
    background: #BC0000;
  }

  &.close {
    color: #111111;
    background-color: rgba(0, 0, 0, 0);
  }
}
.buttonDelete{
  margin-top: 2em !important;
  background: rgba(105, 105, 105, 1) !important;
  text-transform: none !important;
  color: #FFFFFF !important;
  min-width: 150px !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 30px !important;

  &.apply {
    color: #FFFFFF;
    border-radius: 5px;
  }

  &:hover {
    background-color: rgba(65, 65, 65, 1) !important;
  }

  &:active {
    background-color: #111111;
  }

  &.close {
    color: #111111;
    background-color: rgba(0, 0, 0, 0);
  }
}
.titleDelete {
  font-family: Bitter;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 20px;
  max-width: 550px;
}

@media (max-width: 1600px) {
	.collapsableListRow {
    td:nth-child(1) {
      min-width: 350px;
    }
  }
}
