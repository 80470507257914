.popup-content {

    .drag-drop {
        .MuiDialogContent-root {
            display: flex;
            justify-content: center;
        }
    }

    .MuiDialogContent-root {
        padding: 0 60px 24px 60px;
        height: calc(97% - 190px);
        overflow-x: hidden;
    }

    .MuiDialogTitle-root {
        font-family: 'Bitter';
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        margin: 3%;
        white-space: pre-line;
        text-align: center;
    }

    .MuiPaper-root {
        min-width: 60%;
    }

    .selectorSearchMode {
        height: 200px;
    }

    .select-type {
        margin-top: 30px;
        color: rgba(122, 122, 122, 1);
        min-height: 88px;

        .selectorSearchMode {
            height: 200px;
        }

        .MuiFormControl-root {
            padding-top: 5px;
            background-color: rgb(247, 248, 250);
            border-radius: 10px;

            .selectorSearchMode {
                height: 200px;
            }

            .input-label {
                background-color: rgb(247, 248, 250);


                .MuiFormLabel-root {
                    padding-left: 25px;
                    padding-top: 15px;
                    font-family: 'Roboto';
                    font-size: 12px;
                    font-weight: 400;

                    &.Mui-focused {
                        color: rgba(122, 122, 122, 1);
                    }
                }

                .MuiInputBase-root {
                    width: 100%;
                    padding-left: 19px;
                    margin-top: 10px;
                    font-family: 'Roboto';
                    font-size: 15px;
                    height: 50px;

                    .menu-item {
                        color: #111;
                    }

                    &:after {
                        border-bottom: 2px solid rgba(0, 0, 0, 0.42);
                    }
                }
            }

            .MuiFormHelperText-root {
                color: red;
                display: flex;
                font-family: 'Roboto';
                font-size: 12px;
                background: white;
                padding-top: 3px;
                margin-top: 0;

                svg {
                    font-size: 18px;
                    padding-right: 3px;
                }

            }
        }
    }

    .image-description {
        margin-top: 5px;
        color: rgba(122, 122, 122, 1);

        .MuiFormControl-root {
            background-color: rgb(247, 248, 250);
            border-radius: 10px 10px 0 0;

            .MuiFormLabel-root {
                padding-left: 18px;
                font-family: 'Roboto';
                font-size: 12px;

                &.Mui-focused {
                    color: rgba(122, 122, 122, 1);
                }
            }

            .MuiInputBase-root {
                width: 100%;
                padding-left: 19px;
                margin-top: 10px;
                font-family: 'Roboto';
                font-size: 15px;

                .menu-item {
                    color: #111;
                }

                &:after {
                    border-bottom: 2px solid rgba(0, 0, 0, 0.42);
                }
            }
        }
    }

    .MuiDialogActions-root {
        justify-content: center;

        .MuiButtonBase-root.Mui-disabled {
            background-color: rgba(255, 0, 0, 0.25);
            color: white;

        }
    }
}

.image-types {
    max-height: 170px;
}

@media (max-width: 1600px) {
    .popup-content {
        .MuiDialogTitle-root {
            margin: 1%;
        }

        .MuiDialogActions-root {
            margin: 20px;
        }
    }
}

@media (max-width: 1500px) {
    .popup-content {
        .MuiDialogTitle-root {
            margin: 0%;
        }

        .MuiDialogContent-root {
            padding: 0 60px;
        }

        .select-type {
            margin-top: 10px;
        }

        .MuiDialogActions-root {
            padding: 0;
            margin: 20px;
        }
    }
}