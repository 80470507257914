.root,
.MuiPopper-root {
  //width: 100%;
  z-index: 110;

  .MuiAutocomplete-listbox {
    z-index: 11;
    overflow-y: overlay;

    /* width */
    &::-webkit-scrollbar {
      width: 12px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #D9D9D9;
      background: #D9D9D9;
      border-radius: 14px;
      border: 2px solid #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: red;
      border-radius: 18px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #b30000;
    }
  }

  /* 
  .MuiAutocomplete-listbox {
    background-color: white;
    font-size: 16;

    li: {
      padding: 0;

    }
  }

  .MuiAutocomplete-input,
  .MuiInputLabel-root {
    font-size: 16;
    
  }

  label.Mui-focused {
    color: #000000;
  }

  .MuiOutlinedInput-root {
    .Mui-focused fieldset {
      border-color: #000000;
      border: 2px solid black;
    }
  }

  .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    color: black;
  } */
}