.contractContainer {
	font-family: 'Roboto';
	width: 95%;
	margin: auto;

	.heading {
		font-family: 'Bitter';
		font-size: 20px;
		font-weight: 700;
		color: #111111;
	}

	.boxes {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-flow: row;
		align-items: flex-start;
		margin-top: 16px
	}

	.boxContainer {
		width: 50%;
		height: calc(50% - 30px);
		float: left;
		display: flex;
		flex-flow: column;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin-right: 24px;
	}

	.boxRow {
		width: 100%;
		float: left;
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		gap: 24px;
	}

	.boxContainerChild,
	.stateBox {
		img {
			margin-bottom: 3px;
		}
	}

	.boxContainerChild {
		width: calc(50% - 12px);
		float: left;
	}

	.stateBox {
		width: 100%;
	}
}