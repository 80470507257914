.kmzLayer {
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;

    .kmzLayerLoading {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .kmzLayerError {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #F5F5F5;
        z-index: 10;
        align-items: center;
        justify-content: center;
    }


}