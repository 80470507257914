.localizationEnvironment {
    height: 280px;
    width: 95%;
    margin: auto;

    .contentMap {
        height: 100%;
        margin-bottom: 20px;
    }
}

@media (max-width: 1400px) {
    .localizationEnvironment {
        height: 200px;
    }
}