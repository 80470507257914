.sectionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 56px;

  .controversie {
    width: 100%;
    height: 120px;
    margin-top: 16px;
    padding: 1em;
    border-radius: 8px, 8px, 0px, 0px;
    background: #F5F5F5;
    border-bottom: 2px solid #CBCBCB;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: #C0C0C0;
    overflow-y: auto;

    .notInformed {
      font-size: 16px;
      font-weight: 700;
      color: #111111;
    }
  }
}