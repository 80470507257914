.container {
  font-family: 'Roboto';
  width: 95%;
  margin: auto;
}

.sectionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 56px;

  .eligibility {
    margin-top: 2em;
    width: 225px;
  }

  // .eligibilityBox {
  //   padding: 1em;
  // }

  .code {
    margin-left: 4em;
  }

  .codeBox {
    margin-top: 2em;
    padding: 0;
    padding-left: 1em;
    width: 500px;
  }

  .codeTitle {
    margin-bottom: 8px;
  }
}

.sectionFlexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2em;

  .taxom {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;

    svg {
      width: 35px;
      height: 35px;
      top: 593px;
      left: 422px;
      border-radius: 5px;
    }

    .taxomBoxGreen {
      padding: 1em;
      width: 28.5vW;
      background: #1ABC9C40;
    }

    .taxomBox {
      padding: 1em;
      width: 28.5vW;
      background: #EDEDED;
    }

    .taxomValue {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}




.collapsableList {
  padding: 0 20px 20px 20px;

  ul,
  li {
    list-style: none;
    margin: 0 0 0 1px;
    padding: 0;
  }

  li {
    padding-left: 1em;
    border: 1px solid black;
    border-width: 0 0 1px 1px;
  }

  li.container {
    border-bottom: 0px;
  }

  li.empty {
    font-style: italic;
    color: silver;
    border-color: silver;
  }

  li table {
    margin: 0;
    background: white;
    position: relative;
    top: 0.5em;
  }
}

.collapsableListRow {
  width: 100%;
  border: none;
  td:nth-child(1) {
    width: 150px;
    vertical-align: bottom;
    padding: 0 20px 0 10px;
  }
}