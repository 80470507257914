
.selectSearch {
	width: 100%;
	margin-top: -5px;
	padding:1em;
	padding-left: 0;
}

.selectSearchZone, 
.selectSearchSupercenter,
.selectSearchCenter{
	
	margin-top: -5px;
	padding: 1em;
	padding-right: 0;
}
.editPanel {
	overflow: auto;
	overflow-x: hidden;
	position: absolute;
	bottom: 0px;
	top: 150px;
	width: 100%;
}
.container{
	margin: 0;
    position: relative;
    top: 2.5em;
	padding-top: 0.5em;
	background-color: #F7F8FA;
}

.editcontainer {
	margin: auto;
	width: 50%;
	position: inherit;
	margin-left: 40px;
	ul, li { list-style: none; margin: 0 0 0 1px; padding: 0; }
	li { padding-left: 1em;
		border: 1px solid black;
		border-width: 0 0 1px 1px; 
		background-color: #F7F8FA;
		
	}
	
	.zone{
		padding-left: 3em;
		margin-left: 3em;
	}
	.supercenter{
		padding-left: 6em;
		margin-left: 3em;
	}
	.center{
		padding-left: 9em;
		margin-left: 3em;
	}
}

.buttonSave {
	text-transform: none !important;
	background: rgba(255, 0, 0, 1) !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 50px !important;
	padding-right: 50px !important;
	font-family: Roboto !important;
	font-size: 12px !important;
	font-weight: 700 !important;
	line-height: 25px !important;
	letter-spacing: 0px !important;
	text-align: left !important;
	box-shadow: none !important;
	
  
	&.apply {
	  color: #FFFFFF;
	  border-radius: 5px;
	}
  
	&:hover {
	  background: rgba(223, 2, 2, 1) !important;
	  box-shadow: none !important;
	}
  
	&:active {
	  background: #BC0000;
	}
  
	&.close {
	  color: #FFFFFF;
	  background-color: rgba(223, 2, 2, 1)
	}
}

.dialogContentError{
	max-width: 697px;
	min-width: 450px;
	text-align: center;
	padding-top: 6em;
	padding-bottom: 4em;
	margin: 0 auto;
	.buttonConfirm {
	  background: rgba(255, 0, 0, 1);
	  text-transform: none;
	  color: #FFFFFF;
	  border-color: rgba(255, 0, 0, 1);
	  min-width: 150px;
	  font-family: Roboto;
	  font-size: 12px;
	  font-weight: 700;
	  line-height: 30px;
  
	  &.apply {
		color: #FFFFFF;
		border-radius: 5px;
	  }
  
	  &:hover {
		background-color: rgba(223, 2, 2, 1);
	  }
  
	  &:active {
		background-color: rgba(188, 0, 0, 1);
	  }
  
	  &.close {
		color: #FFFFFF;
		background-color: rgba(188, 0, 0, 1);
	  }
	}
	.serverError {
	  position: relative;
	  width: 697px;
	  margin-top: -15%;
  
	  background: #FFFFFF;
	  border-radius: 20px;
	
	  .serverErrorContent {
		margin: 50px 0;
  
		svg {
		  width: auto;
		  height: auto;
		  margin-bottom: 32px;
		  margin-top: 1em;
		}
	
		.title {
		  font-family: 'Bitter';
		  font-style: normal;
		  font-weight: 700;
		  font-size: 24px;
		  line-height: 25px;
		  text-align: center;
		  margin-bottom: 0.5em;
		}
	
		.subtitle {
		  font-family: 'Roboto';
		  font-style: normal;
		  font-weight: 400;
		  font-size: 14px;
		  line-height: 25px;
		  text-align: center;
		  margin-bottom: -1em;
		}
	  }
	}
}