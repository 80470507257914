.searchMethodContainer {
	display: flex;
	flex-flow: row;
	margin: 20px 0;
	position: relative;
	height: 57px;
	.selectSearchMethod {
		width: 200px;
		margin-right: 20px;
		height: fit-content;
	}
	.inputSearchMethod {
		position: relative;
		width: 400px;
		input {
			width: 85%;
		}
		.searchIcon {
			position: absolute;
			width: fit-content;
			top: 20px;
			right: 20px;
		}
	}
	.addUser {
		color: #FF0000;
    cursor: pointer;
	}
  .addUser:hover {
    color: #DF0202;

  }
  .addUser:active {
    color: #BC0000
  }
	.addUserDisabled {
		cursor: default;
		color: rgba(100, 0, 0, 0.25);
	}
	.addUser, .addUserDisabled {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 16px;
		position: absolute;
		right: 0;
		bottom: 0;
	}
}
