.bimContainer {
    .bimTable {
        margin-top: 14px;

        thead th {
            padding: 20px 0;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: center;
            color: #696969;
            border-bottom: 1px solid rgba(192, 192, 192, 1);
        }

        tbody tr td {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            color: #111111;
        }

        .accionaUseColumn {
            width: 28%;
        }

        .levelColumn {
            width: 28%;
        }

        .checkColumn {
            width: 8%;
        }

        .levelContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            >div:last-of-type {
                width: 70%;
            }

            .levelDescription {
                width: 93px;
                height: 38px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 700;
                line-height: 38px;
                letter-spacing: 0em;
                text-transform: uppercase;
                margin-right: 5%;

            }
        }
    }
}