@-moz-document url-prefix() {
  .tdFlag {
    position: relative;

    .span {
      position: absolute;
      left: 2px;
      top: 1px;
    }
  }
}

.box {
  height: 100%;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 22px 20px;
  //margin-bottom: 56px;
  text-align: left;
  position: relative;
  min-width: 220px;

  .fieldWrapper {
    display: flex;
    flex-flow: row;
  }

  .field {
    height: 100%
  }

  .title,
  .value {
    inline-size: 18vw;
    overflow-wrap: break-word;
  }

  .title {
    color: #696969;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 3px;
  }

  .value {
    color: #111111;
    font-size: 16px;
    font-weight: 700;
  }

  .switchButtonContainer {
    position: absolute;
    top: 20%;
    right: 5%;

    .switchButton {
      cursor: pointer;
    }

    .switchButton:hover {
      filter: brightness(87.5%);
    }

    .switchButton:active {
      filter: brightness(84.5%);
    }
  }
}