.filters {
    .button {
        position: relative;
        text-align: left;
        left: 4em;


        button {
            padding: 20px 25px;
            height: 60px;
            border-radius: 30px;
            background-color: white;
            cursor: pointer;
            box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.2);
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            color: #7A7A7A;
            text-transform: capitalize;
            svg {
                color: #C3CAD9;
            }

            &:hover {
                color: #FF0000;
                svg {
                    color: #FF0000;
                }
            }

            &:active {
                color: #BC0000;
                svg {
                    color: #BC0000;
                }
            }

            .title {
                padding-right: 15px;
            }

            svg {
                font-size: 17px;
            }

            .count {
                width: 30px;
                height: 30px;
                background: #F5B6B6;
                border-radius: 30px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                color: #FF0000;
                align-items: center;
                display: flex;
                justify-content: center;
            }
        }

        button:disabled {
            color: red;
            border: 1px solid red;
            font-weight: 600;
            cursor: default;
        }
    }

    .cardFilters {
        position: absolute;
        z-index: 10;
        top: 10rem;
        margin-top: 18px;
        left: 2rem;
        width: 323px;
        background: #FFFFFF;
        border-radius: 22px;

        >div {
            border-radius: 22px;
        }

        .clearFilters {
            top: calc(50% - 13px/2 - 295.5px);
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            letter-spacing: 0.5px;
            color: #FF0000;

            &:hover {
                color: #DF0202;
            }

            &:active {
                color: #BC0000;
            }
        }

        .cardContent {
            padding-bottom: 0;
            padding-top: 0;
            max-height: 60vh;
            overflow-y: overlay;

            /* width */
            &::-webkit-scrollbar {
                width: 12px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #D9D9D9;
                background: #D9D9D9;
                border-radius: 14px;
                border: 2px solid #fff;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: red;
                border-radius: 18px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #b30000;
            }
        }
    }
    .buttons {
        padding: 20px 10px;
        .close {
            color: #111111;
            width: fit-content;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            cursor: pointer;
            margin-right: 10px;
            &:hover {
                color: #DF0202;
                background: #FFFFFF;
            }
            &:active {
                color: #BC0000;
                background: #FFFFFF;
            }
        }
        

        button {
            width: 250px;
            height: 49px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 25px;
            text-transform: capitalize;
            &.apply {
                background: #FF0000;
                color: #FFFFFF;
                border-radius: 5px;

                &:hover {
                    background: #DF0202;
                    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.2);
                }

                &:active {
                    background: #BC0000;
                }
            }
        }
    }
}