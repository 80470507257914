.dialog {
    min-width: 650px !important;
}

.dialogContent {
    max-width: 600px;
    min-width: 485px;
    text-align: center;
    padding: 5em;
    padding-top: 6em;
    padding-bottom: 6em;
    margin: 0 auto;

    p {
        font-family: Bitter;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(17, 17, 17, 1);
        margin-bottom: 2em;
    }
}

.buttonClose {
    text-transform: none !important;
    background: #FF0000 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    box-shadow: none !important;

    &:hover {
        background: rgba(223, 2, 2, 1) !important;
        box-shadow: none !important;
    }

    &:active {
        background: #BC0000;
    }

}

.buttonSecondary {
    text-transform: none !important;
    background: rgba(0, 0, 0, 0) !important;
    border: solid 1px #FF0000 !important;
    color: #FF0000 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    box-shadow: none !important;

    &:hover {
        background: none !important;
        color: rgba(223, 2, 2, 1) !important;
        border-color: rgba(223, 2, 2, 1) !important;
    }

    &:active {
        background: #BC0000;
    }

}