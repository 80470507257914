.gm-style-iw-a {
  .obra-infowindow {
    min-width: 200px;
    max-width: 300px;
    text-align: left;
    margin: 0;
    margin-right: 5px;
    padding: 8px;
    padding-left: 11px;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      padding: 0px;

      .MuiListItemText-root {
        margin: 0px;
        
      }

      .MuiListItemIcon-root {
        min-width: 25px;
      }

    }

    .name .MuiTypography-root {
      font-family: 'Bitter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      color: #111111;
      padding-left: 5px;
    }

    .code .MuiTypography-root {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #7A7A7A;
      padding-left: 5px;
    }

    .type,
    .date {
      margin: 6px 0;
      padding-left: 5px;

      .MuiTypography-root {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #696969;
      }

      .MuiListItemIcon-root {
        color: rgba(0, 0, 0, 0);

        svg {
          font-size: 17px;
          width: auto;
        }
      }
    }

    .type {
      margin-bottom: 15px;
      padding-left: 5px;

      .MuiListItemIcon-root {
        svg {
          font-size: 16px;
        }
      }
    }

    .status {

      //background: rgba(26, 188, 156, 0.25);
      border-radius: 21px;
      width: fit-content;
      margin: 10px 0;
     


      .MuiTypography-root {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-transform: uppercase;
        //color: #1ABC9C;
        padding: 5px 7px;

      }
    }

    .link {
      display: flex;
      padding: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }

      .MuiListItemText-root {
        width: 65%;

        .MuiTypography-root {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          text-align: right;
          text-transform: uppercase;
          color: #FF0000;
        }
      }

      .MuiListItemIcon-root {
        width: 35%;
        color: #FF0000;
      }
    }
  }

  button.gm-ui-hover-effect span {
    top: 8px;
    right: 7px;
    position: relative;
  }
}