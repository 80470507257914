.optionsMenu {
  span {
    display: flex;
    /* Bold/Bold 13 */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 25px;
    /* identical to box height, or 192% */
    /* Negro */
    color: #111111;
  }
}

.optionMenu {
  img {
    width: 25px;
  }
}

li {
  padding: 10px;
}

.optionMenu:hover {
  background-color: rgba(255, 0, 0, 0.05) !important;
}


.LanguageSelected {
  span {
    display: flex;
    margin-left: 10px;
    /* Bold/Bold 13 */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 25px;
    /* identical to box height, or 192% */
    /* Gris 4 */
    color: #7A7A7A;
  }
}

.LanguageSelected {
  span:hover {
    color: red !important;
  }
}
