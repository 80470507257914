.autocompleteContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  gap: 24px;
  width: 100%;

 

  .titleValue1 {
    width: 25%;
    min-width: 125px;
  }

  .titleValue2 {
    width: 73%;
  }

  .titleValue1,
  .titleValue2 {
    .arrowDownIcon,
    .arrowUpIcon {
        position: absolute;
        width: 10px;
        bottom: 20px;
        right: 0px;
    }
    .arrowUpIcon {
      transform: rotate(180deg);
    }
   
    :global(.MuiInput-root) {
      height: 25px;
      align-items: baseline;

      input {
        padding: 0px;
      }

      ::placeholder {
        color: #222222
      }
    }
  }
}