.extinctionSpeciesProtectionZonesContainer {
  margin-top: 16px;
}

.noDataPic {
  padding: 40px 0;
  text-align: center;
  img{
		width: 232px;
		height: 180px;
	}
  p {
		padding-top: 10px;
		width: 400px;
		margin: 16px auto;
	}
}

.row {
  display: flex;
  flex-flow: row;
  gap: 24px;
  justify-content: space-between;

  .column1 {
    width: 53%;
  }

  .column2 {
    width: 37%;
  }
}

.fullWidthForScroll {
  width: 100%;
}