.containerMap {
  text-align: center;
  height: 100%;
  width: 100%;
  .mapProjects {
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .tagsOverlay {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 20;
  }
}