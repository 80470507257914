.font {
  font-family: 'Roboto';
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.5px;
}

.collapsableContainer {
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 40px 30px ;
  .collapsableTitleWrapper {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    .collapsableTitle {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      width: 85%;
    };
    .arrowBtn {
      margin-top: 5px;
    }
    .arrowBtnRotate svg{
      margin-top: 5px;
      transform: rotate(90deg);
    }
    .title {
      margin-left: 20px;
    }
    .appliedFilters {
      @extend .font;
      font-weight: 700;
      background-color: #F5B6B6;
      border-radius: 30px;
      height: 30px;
      width: 30px;
      display:flex;
      justify-content:center;
      align-items:center;
      margin-left: 20px;
      color: #FF0000;
    }
    .deleteFiltersDisabled {
      @extend .font;
      width: 220px;
      color: #D9D9D9;
      font-weight: 400;
      text-align: right;
    }
    .deleteFilters {
      @extend .font;
      width: 220px;
      color: #FF0000;
      font-weight: 400;
      text-align: right;
    }
    .deleteFilters:hover {
      cursor: pointer;
      color: #DF0202;
    }
    .deleteFilters:active {
      color: #BC0000;
    }
  }
}