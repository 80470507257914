.userModeSwitch {
  margin-left: 1rem;
  background-color: #ffffff;
  border-radius: 20px;
  z-index: 1;

  .dropdownMenu {
    position: relative;

    button {
      background-color: #ffffff00;
      color: #000000;
      border: none;
      border-radius: 20px;
      padding: 10px 35px 10px 20px;
      font-size: 15px;
      font-weight: 700;
      white-space: nowrap;
      cursor: pointer;

      svg {
        position: absolute;
        top: 20%;
      }
    }

    button:hover {
      color: #ff0000;
    }

    button:active {
      color: #BC0000;
    }

    .dropdownMenuOptions {
      position: absolute;
      background-color: #FFFFFF;
      top: 20%;
      left: 0;
      right: 0;
      font-size: 1em;
      list-style: none;
      padding: 15px 0 7px 0;
      text-align: center;
      border-radius: 0 0 20px 20px;
      box-shadow: 0px 8px 6px 0px #eaeaea;
      width: 100%;
      z-index: -1;

      li {
        padding: 10px 0;
        text-decoration: none;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
        width: 100%;
      }

      li:hover {
        background-color: #FF000005;
      }

      li:active {
        background-color: #FF000010;
      }
    }
  }
}