.filterContainer {
  height: 100%;
  min-height: 100%;
}

.filterPaper {
  border-radius: 12px !important;
  height: 100%;
}

.infiniteScroll {
  overflow: overlay;
  overflow-x: hidden;
  min-height: 90%;
  max-height: 90%;
  scrollbar-gutter: stable;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;

  &:-webkit-scrollbar {
    position: fixed;
    width: 4;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    padding: 1;
    border-radius: 10;
  }

  &:hover::-webkit-scrollbar {
    width: 6;
    border-radius: 30px;
  }
}