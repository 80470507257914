.noDataPic {
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  .noDataWrapper{
    margin-top: 16px;
    margin-bottom: 56px;
    .paragraphs {
      margin-top: 33px;
      .paragraph {
        margin-top: 7px;
        width: 450px;
        font-family: 'Roboto';
        font-size: 16px;
      }
    }
  }
}