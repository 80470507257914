.clusterLayer {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 1;

  .clusterLayerLoading {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .clusterLayerError {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #F5F5F5;
    z-index: 10;
    align-items: center;
    justify-content: center;
  }


}

.filterNotFound {
  position: absolute;
  top: 500px;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 600px;
  background: white;
  text-align: center;
  border-radius: 1em;
  font-family: 'Bitter';
  font-style: normal;
  padding-left: 5em;
  padding-right: 5em;

  img {
    width: 250px;
    height: 250px;
    margin-left: -30px;
    margin-top: 90px;
  }

  .titleError {
    margin-top: 1em;
    font-family: 'Bitter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #111111;
  }

  .titleError2 {
    font-family: 'Bitter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #111111;
  }

  .descriptionError {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #111111;
  }
}
