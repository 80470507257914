.titleContainer {

  .title {
    padding-right: 6px;
    padding-left: 6px;
    min-height: 43px;

    svg {
      color: rgba(191, 192, 200, 1);
    }
    &:hover {
      cursor: pointer;
    }
  }

  .hoverableTitle {
    @extend .title;
    &:hover {
      background-color: rgba(33, 33, 33, 0.05);
    }
  }

  .titleBadgeTypographyContainer {
    width: calc(80% - 0.25rem);
    margin-left: 0.25rem;
  }

  .titleTypography {
    color: #000000;
    padding: 0;
    font-size: 13px;
  }
}