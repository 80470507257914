.tableRow {
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
}
.tableRowParent {
  display: flex;
  flex-flow: row;
  margin-top: 20px;
  width: 100%;
}
.tableRowParent, .tableRowChild {
  margin-left: 15px;
}
.viewer, .viewer_vip {
  color: #006FF5;
  background-color: rgba(0, 111, 245, 0.25);
}
.editor {
  color: #61BC1A;
  background-color: rgba(216, 238, 198, 1);
}
.validator {
  color: #F39C12;
  background-color: rgba(243, 156, 18, 0.25);
}
.administrator {
  color: #F93939;
  background-color: rgba(255, 0, 0, 0.25);
}
.download {
  color: rgb(105, 190, 156);
  background-color: #C5EEE6;
}
.viewer, .editor, .validator, .administrator, .viewer_vip, .download {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 5px;
  margin: 5px 10px 5px 0;
  width: 150px;
  text-align: center;
}
.nameCell, .nameCellCollapsable {
  min-width: 645px;
  margin: auto 0;
  .nameContainer {
    display: flex;
    flex-flow: row;
  }
}
.nameCellCollapsable {
  cursor: pointer;
}
.rolesCell {
  width: 50%;
  .rolesWrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
  }
}
.addRoleCell {
  margin: auto 20px auto auto;
  .editBtn {
    color: #FF0000;
    cursor: pointer;
  }
  .editBtn {
    width: 100px;
    text-align: right;
    flex-wrap: wrap;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 16px;
  }
  .editBtn:hover {
    color: #DF0202;
  }
  .editBtn:active {
    color: #BC0000;
  }
}
.nameContainer {
  position: relative;
  .nameContainerIcon {
    position: absolute;
    top: -2px;
    left: -25px;
    color: #696969;
    
  }
}

.dialogContentError{
	max-width: 697px;
	min-width: 450px;
	text-align: center;
	padding-top: 6em;
	padding-bottom: 4em;
	margin: 0 auto;
	.buttonConfirm {
	  background: rgba(255, 0, 0, 1);
	  text-transform: none;
	  color: #FFFFFF;
	  border-color: rgba(255, 0, 0, 1);
	  min-width: 150px;
	  font-family: Roboto;
	  font-size: 14px;
	  font-weight: 700;
	  line-height: 30px;
  
	  &.apply {
		color: #FFFFFF;
		border-radius: 5px;
	  }
  
	  &:hover {
		background-color: rgba(223, 2, 2, 1);
	  }
  
	  &:active {
		background-color: rgba(188, 0, 0, 1);
	  }
  
	  &.close {
		color: #FFFFFF;
		background-color: rgba(188, 0, 0, 1);
	  }
	}
	.serverError {
	  position: relative;
	  width: 697px;
	  margin-top: -15%;
  
	  background: #FFFFFF;
	  border-radius: 20px;
	
	  .serverErrorContent {
      margin: 50px 0;
    
      svg {
        width: auto;
        height: auto;
        margin-bottom: 32px;
        margin-top: 1em;
      }
    
      .title {
        font-family: 'Bitter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 0.5em;
      }
	
      .subtitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        margin-bottom: -1em;
      }
	  }
	}
}

@media (max-width: 1600px) {
  .rolesCell {
    width: 60%;
  }
	.nameCell, .nameCellCollapsable {
    min-width: 320px;
  }
}
