@-moz-document url-prefix() {
    .tdFlag {
        position: relative;

        .span {
            position: absolute;
            left: 2px;
            top: 1px;
        }
    }
}

.box {
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 0;
    text-align: left;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;




    .title,
    .value {
        inline-size: 18vw;
        overflow-wrap: break-word;


    }


    .title {
        color: #696969;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;

        margin-bottom: 3px;
        padding: 30% 10% 0;
        width: fit-content;
    }

    .value {
        color: #111111;
        font-family: Bitter;
        font-size: 48px;
        font-weight: 700;

        letter-spacing: 0px;
        text-align: left;

        padding: 0 10% 25%;
        width: 100%;
    }

    .field {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .footer {
        height: 20%;
        text-transform: uppercase;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 7%;
        text-align: center;


    }
}