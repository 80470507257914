.addButtonWrapper {
    margin: 10px 12px 12px 0;
    display: flex;
    .addButton {
      margin-left: auto;
      color: #FF0000;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.5px;
    }
  }
  
  .addButton:hover {
    cursor: pointer;
    color: #DF0202;
  }
  
  .addButton:active {
    cursor: pointer;
    color: #BC0000;
  }