#form-file-upload {
    height: 18rem;
    width: 20rem;
    max-width: 100%;
    text-align: center;
    position: relative;
    margin-top: 1rem;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: dashed;
    border-color: #cbd5e1;
    border-radius: 5px;
    box-shadow: 2px 5px #f7f7f7;
    margin-bottom: 10px;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    margin-top: 1rem;
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    font-family: 'Roboto';
    background-color: white;
    color: red;
}

.upload-button:hover {
    color: rgba(223, 2, 2, 1);
}

.upload-button:active {
    color: rgba(188, 0, 0, 1);
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.digitalForm .loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
}

.error {
    color: #A71042;
}