.sectionFlexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 26px;

  > div{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content:flex-start;
    column-gap: 24px;
    svg {
      fill: none;
    }
  }

  > div:first-child {
    margin-bottom: 4px;
  }

  .taxom {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 28px;
    width: calc(33% - 10px);

    svg {
      width: 35px;
      height: 35px;
      top: 593px;
      left: 422px;
      border-radius: 5px;
    }

    .taxomBoxGreen {
      padding: 1em;
      width:auto;
      background: #1ABC9C40;
    }

    .taxomBox {
      padding: 1em;
      width: auto;
      background: #EDEDED;
    }

    .taxomValue {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

.container {
  margin-top: 16px;
}

.containerList {
  margin-left: 50px;
}

.branchTree {
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  width: 100%;
  height: 40%;
}
.branchTreeLarge {
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 3.5rem;
}

/* .collapsableList {
  padding: 0 20px 27px 20px;
  margin-bottom: 16px;

  ul,
  li {
    list-style: none;
    margin: 0 0 0 1px;
    padding: 0;
  }

  li {
    padding-left: 1em;
    border: 1px solid black;
    border-width: 0 0 1px 1px;
    height: 40px;
  }

  li.container {
    border-bottom: 0px;
  }

  li.empty {
    font-style: italic;
    color: silver;
    border-color: silver;
  }

  li table {
    margin: 0;
    background: #F7F8FA;
    position: relative;
    top: 0.5em;
    width: 101.5%;
  }
} */

/* .collapsableListRow {
  width: 100%;
  border: none;

  td:nth-child(1) {
    width: 150px;
    vertical-align: bottom;
    padding: 0 20px 0 10px;
  }
} */

/* .titleValue{
  font-family: 'Roboto';
  background-color: #F5F5F5;
  border-bottom: 1px solid #C0C0C0;
  border-radius: 5px 5px 0 0;
  padding: 12px 16px;
  .title {
    font-size: 14px;
    font-weight: 400;
    color: #696969;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value {
    color: #111111;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
} */