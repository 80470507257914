.container{
  margin-top: 54px;
}

.sectionContainer {
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 72px;
  gap: 24px;

  .impactBoolean{
    width: calc(25% - 18px);
  }

  .impact {
    width: calc(25% - 18px);
    min-width: 200px;
  }

  .box {
    width: auto;
  }

  .title {
    margin-bottom: 8px;
  }
}

.exempt{
  width: 200px;
  margin-top: 16px;
  :global(#exemptNo-value){
    color: red !important;
  }
  :global(#exempt-value){
    color: green !important;
  }
}

.boxBoolean {
    padding-top: 16px;
    padding-bottom: 16px;
    width: auto;
    min-height: 58px;
}

.valueBoolean {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    width: calc(33% - 10px);
}