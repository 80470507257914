.locationInfo {
  width: 100%;
  margin: 2% auto;

  table {
    width: 100%;

    td {
      width: 45%;
    }

    td:nth-child(1) {
      border-right: 5px solid transparent;
    }

    td:nth-child(2) {
      border-left: 5px solid transparent;
    }
  }
}

.section {
  margin-bottom: 40px;

  .editionHeading {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .addButton {
      color: #FF0000;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
    }

    .addButton:hover {
      color: #DF0202;
    }

    .addButton:active {
      color: #BC0000;
    }
  }

  .otherTownsContainer {
    width: 100%;

    .additionalTown {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .town {
        width: 53%;
      }

      .zipcode {
        width: 41%;
      }

      .deleteBtn {
        border-radius: 8px;
        border: none;
        background-color: #696969;
        height: 60px;
        width: 60px;
      }

      .deleteBtn:hover {
        cursor: pointer;
        background-color: #414141
      }

      .deleteBtn:active {
        background-color: #111111;
      }
    }
  }
}

.halvedSection {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .section {
    width: 49%;
    min-width: 580px;
  }
}

.heading {
  font-family: 'Bitter';
  font-size: 20px;
  font-weight: 700;
}

.otherTownsContainer {
  margin-top: 40px;
}

.extinctionSpeciesProtectionZonesContainer {
  width: 100%;
  margin-top: 40px;
  border-collapse: collapse;
  border: none;

  .speciesName {
    width: 55%;
  }

  .dangerLevel {
    width: 43%;
  }
}