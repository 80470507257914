.header {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.08);
  z-index: 3;
}

.mainHeader {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 1rem 2rem 1rem 4rem;
  font-size: 15px;
  flex-direction: row;
  text-align: center;
  align-items: center;
}

.version {
  position: relative;
  font-size: 9px;
  font-weight: 800;
  top: 19px;
  left: 3px;
}

.mainImages {
  display: flex;
  cursor: pointer;
  .phiaName {
    margin-top: 3px;
    margin-left: 10px;
  }
}

.leftActions {
  display: flex;
}

.boxLanguage {

  right: 4em;

  span {
    margin-top: 2px;
    font-size: 13px;
    line-height: 25px;
  }

  img {
    width: 30px;
  }
}

.userLogin {
  display: flex;

  right: 10em;

  span {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 700;
    line-height: 25px;
  }

  img {
    display: flex;
    width: 30px;
  }
}

.avatar {
  margin-top: -2px;
}

.boxUserName {
  display: flex;

}

.labelName {
  //styleName: Bold/Bold 13;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 1em;
  margin-top: 2px;
}

.userLogin:hover,
.adminPanel:hover {
  div {
    color: rgba(223, 2, 2, 1);
  }
}

.optionMenu:hover {
  background-color: rgba(255, 0, 0, 0.05) !important;
}


.optionMenu {
  img {
    width: 20px;
    margin-left: 1em;
  }

  span {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    margin-left: 1em;
    margin-right: 1em;
  }

  padding: 10px;
  display: flex;
  width: 100%;
}

.optionMenu:focus {
  background: white !important;
}

.menuItem {
  background: white;
  width: 100%;
}

.secondaryHeader {
  height: fit-content;
  margin-bottom: 12px;
}