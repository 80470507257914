.autocompleteContainer {
  width: 100%;
  position: relative;

  input {
    margin-top: -5px;
    margin-bottom: -4px;
  }
}

.searchIcon {
  position: absolute;
  bottom: 10px;
  right: 0px;
}

.titleValueContainer {
  :global(#searchAutocomplete-title) {
    margin-top: 3px;
  }

  .arrowDownIcon,
  .arrowUpIcon {
    position: absolute;
    width: 10px;
    bottom: 20px;
    right: 0px;
  }

  .arrowUpIcon {
    transform: rotate(180deg);
  }
}
