.title {
  color: #696969;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 3px;
}

.description {
  font-size: 16px;
  font-weight: 700;
  min-height: 18px;
  overflow-wrap: break-word;
  color: #111111;
}
