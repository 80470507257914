.box {
	height: 100%;
	background-color: #FFFFFF;
	border-radius: 20px;
	padding: 30px;
	margin-bottom: 16px;
	text-align: left;
	display: flex;
	flex-flow: row;
	table {
		height: 100%;
		width: 50%;
		tr {
			td{
				vertical-align: middle;
				height: 50px;
			}
			.tdFlag {
				position: relative;
				span {
				position: absolute;
				left: 12px;
				top: 14px;
				}
			}
		}
	}
	.picture {
		height: 50px;
		margin-bottom: 5%;
	}
	.title, .value {
		inline-size: 18vw;
		overflow-wrap: break-word;
	}
	.title {
		color: #7A7A7A;
		font-size: 12px;
		font-weight: 400;
		margin-bottom: 3px;
	}
	.value{
		color: #111111;
		font-size: 15px;
		font-weight: 700;
	}
}