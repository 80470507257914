.sectionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  margin-bottom: 70px;
  svg {
    fill: none;
  }
  
  .eligibility {
    margin-top: 14px;
    width: 257px;
  }

  .eligibilityBox {
    padding: 0.5em 1em;
    width: 225px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .codeBox {
    margin-top: 16px;
    padding: 0;
    padding-left: 1em;
    width: 500px;
  }

  .codeTitle {
    margin-bottom: 8px;
  }
}