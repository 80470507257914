.MuiPaper-root {
    .MuiList-root {
        .Mui-selected {
            background-color: rgba(255, 0, 0, 0.05);
        }

        .MuiMenuItem-root {
            padding: 0;


            .menu-item {
                padding: 6px 16px;
                width: 100%;
                display: flex;

                &:hover {
                    background-color: rgba(255, 0, 0, 0.1);
                }
            }
        }
    }
}

.multiple-select {
    width: 100%;
    
    >div.MuiOutlinedInput-root {
        width: 100%;
        min-height: 60px;

        .MuiSelect-multiple {
            z-index: 1;
            background-color: rgba(244, 244, 244, 0);
            -webkit-text-fill-color: inherit;
        }
        
        .MuiSvgIcon-root {
            z-index: 1;
        }

        fieldset {
            background-color: #fff;
            border: none;
            border-bottom: 1px solid #c0c0c0;
            border-radius: 5px 5px 0 0;
        }


        &.Mui-disabled {
            margin-top: -4px;



            fieldset {
                background-color: rgba(244, 244, 244, 1);
                border: none;
                border-bottom: 1px solid #c0c0c0;
                border-radius: 5px 5px 0 0;
            }

            .MuiSvgIcon-root {
                z-index: 0;
            }
        }

        &.Mui-focused {
            fieldset {
                border: none;
                border-bottom: 1px solid #7A7A7A;
            }
        }

        .chip-protected-space {
            border-radius: 5px;

            .MuiSvgIcon-root {
                color: inherit;
                width: 20px;
            }
        }
    }

}