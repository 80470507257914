.validateContainer {
  button {
    border: 1px solid #FF0000;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
    color: #FF0000;
    background-color: #F7F8FA;
    padding: 10px 15px 10px 40px;
    cursor: pointer;
    position: relative;
    margin-left: 10px;

    img {
      height: 20px;
      position: absolute;
      left: 10px;
    }
  }

  button:active {
    border: 1px solid #BC0000;
    color: #BC0000;
  }

  button:disabled {
    border: 1px solid #FF000050;
    color: #FF000050;
    background-color: #F7F8FA;
    cursor: default;

    img {
      opacity: 50%;
    }
  }
}

.dialog {
  min-width: 650px !important;
}

.dialogContent {
  max-width: 600px;
  min-width: 485px;
  text-align: center;
  padding: 5em;
  padding-top: 6em;
  padding-bottom: 6em;
  margin: 0 auto;

  p {
    font-family: Bitter;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(17, 17, 17, 1);
    margin-bottom: 2em;
  }
}

.buttonCancel {
  text-transform: none !important;
  color: rgba(255, 0, 0, 1) !important;
  border-color: rgba(255, 0, 0, 1) !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  letter-spacing: 0px !important;
  text-align: left !important;

  &.apply {
    color: #FFFFFF;
    border-radius: 5px;
  }

  &:hover {
    background: none !important;
    color: rgba(223, 2, 2, 1) !important;
    border-color: rgba(223, 2, 2, 1) !important;
  }

  &:active {
    background: #BC0000;
  }

  &.close {
    color: #111111;
    background-color: rgba(0, 0, 0, 0);
  }
}

.buttonAccept {
  text-transform: none !important;
  background: rgba(255, 0, 0, 1) !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  box-shadow: none !important;

  &.apply {
    color: #FFFFFF;
    border-radius: 5px;
  }

  &:hover {
    background: rgba(223, 2, 2, 1) !important;
    box-shadow: none !important;
  }

  &:active {
    background: #BC0000;
  }

  &.close {
    color: #FFFFFF;
    background-color: rgba(223, 2, 2, 1)
  }
}


.buttonRetry {
  text-transform: none !important;
  background: rgba(255, 0, 0, 1) !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  box-shadow: none !important;

  &.apply {
    color: #FFFFFF;
    border-radius: 5px;
  }

  &:hover {
    background: rgba(223, 2, 2, 1) !important;
    box-shadow: none !important;
  }

  &:active {
    background: #BC0000;
  }

  &.close {
    color: #FFFFFF;
    background-color: rgba(223, 2, 2, 1)
  }
}

.approveRejectContainer {
  display: flex;
  margin-left: 10px;

  button {
    padding-left: 20px;
    padding-right: 22px;
    height: 40px;

    img {
      height: 25px;
      position: absolute;
      margin-top: -12px;
    }
  }
}

.approveButton {
  border: 1px solid #1ABC9C !important;
  div{
    padding-left: 20px;
  }
}

.approveButtonFinal{
  border: 1px solid #1ABC9C !important;
  img{
    margin-top: -6px !important;
  }
  div{
    color: #1ABC9C;
    padding-left: 20px;
  }
}

.rejectedButton {
  button:disabled {
    border: 1px solid #FF000050;
    color: #FF000050;
    background-color: #F7F8FA;
    cursor: default;

    img {
      opacity: 5%;
    }
  }
}

.errorSection {
  display: flex;
  align-items: flex-start;
  margin-top: 1em;
  margin-bottom: 1em;
}

.dialogContentSendError {
  width: 90%;
  padding: 2em;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;

  .titleError {
    font-family: Bitter;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
  }

  .titleContentError {
    font-weight: bold;
  }

  .validationSection {
    margin-top: 2em;
    max-height: 30vH;
    overflow-y: scroll;
  }

  .iconError {
    margin-right: 1em;
  }

  .buttonConfirm,
  .buttonApprove {
    margin-top: 2em;
    background: rgba(255, 0, 0, 1);
    text-transform: none;
    color: #FFFFFF;
    border-color: rgba(255, 0, 0, 1);
    min-width: 150px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;

    &.apply {
      color: #FFFFFF;
      border-radius: 5px;
    }

    &:hover {
      background-color: rgba(223, 2, 2, 1);
    }

    &:active {
      background-color: rgba(188, 0, 0, 1);
    }

    &.close {
      color: #FFFFFF;
      background-color: rgba(188, 0, 0, 1);
    }
  }

  .titleConfirm {
    font-family: Bitter;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
  }

  .descriptionConfirm {
    margin-top: 1em;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
  }


  .buttonCancel {
    margin-top: 2em;
    text-transform: none;
    color: #FFFFFF;
    border-color: rgba(255, 0, 0, 1);
    min-width: 150px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;

    &.apply {
      color: #FFFFFF;
      border-radius: 5px;
    }

    &:hover {
      background: none !important;
      color: rgba(223, 2, 2, 1) !important;
      border-color: rgba(223, 2, 2, 1) !important;
    }

    &:active {
      background: #BC0000;
    }

    &.close {
      color: #111111;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.dialogContentError {
  width: 100%;
}