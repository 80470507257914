.layerSpinner {
  position: fixed;
  right: 0;
  top: 0;
  background-color: grey;
  width: 100%;
  height: 100vH !important;
  z-index: 10000;
  opacity: 0.2;
}

.spinner {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  z-index: 10000;
}