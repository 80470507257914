.filterWrapper {
  margin-bottom: 15px;
}

  .selectorSearchMode {
    margin: 10px 12px 0px 12px;
    width: -webkit-fill-available;
    width: -moz-available;
    text-align: left;
  }

  .selectorSearchMode div {
    color: #696969;
  }
  