.language-select {

    .Mui-focused {
        background-color: #f7f8fa00 !important;
    }

   .Mui-selected {
     background-color: white !important;
    }

    *:focus {
        background-color: #f7f8fa00 !important;
    }
}
