.checkSaved {
    width: 30px;
}

.dialog {
    min-width: 100px !important;
}

.dialogContent {
    max-width: 535px;
    min-width: 450px;
    text-align: center;
    padding: 4em;
    padding-top: 6em;
    padding-bottom: 6em;
    margin: 0 auto;

    p {
        font-family: Bitter;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(17, 17, 17, 1);
        margin-bottom: 2em;
    }
}

.buttonBack {
    text-transform: none !important;
    color: rgba(255, 0, 0, 1) !important;
    border-color: rgba(255, 0, 0, 1) !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
    letter-spacing: 0px !important;
    text-align: left !important;

    &.apply {
        color: #FFFFFF;
        border-radius: 5px;
    }

    &:hover {
        background: none !important;
        color: rgba(223, 2, 2, 1) !important;
        border-color: rgba(223, 2, 2, 1) !important;
    }

    &:active {
        background: #BC0000;
    }

    &.close {
        color: #111111;
        background-color: rgba(0, 0, 0, 0);
    }
}

.buttonDiscard {
    text-transform: none !important;
    background: rgba(105, 105, 105, 1) !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    box-shadow: none !important;

    &.apply {
        color: #FFFFFF;
        border-radius: 5px;
    }

    &:hover {
        background: rgba(65, 65, 65, 1) !important;
        box-shadow: none !important;
    }

    &:active {
        background: #BC0000;
    }

    &.close {
        color: #111111;
        background-color: rgba(0, 0, 0, 0);
    }
}