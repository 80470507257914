.adminPanel {
	overflow: auto;
	overflow-x: hidden;
	position: absolute;
	bottom: 0px;
	top: 180px;
	width: 100%;
}

.container {
	margin: auto;
	width: 95%;
}

@media (max-width: 1300px) {
	.adminPanel {
		top: calc(520px - 26vw);
	}
}

@media (max-width: 800px) {
	.adminPanel {
		top: calc(600px - 26vw);
	}
}