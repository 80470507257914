.addBtnContainer {
	position: relative;
	width: 100%;
	height: 20px;
	.addBtn {
		position: absolute;
		right: 0;
		width: fit-content;
	}
}

.worktypesTable {
	margin-top: 11px;
	width: 100%;
}
.workTypes, .magnitudes {
	display: flex;
	flex-flow: row;
	flex-wrap: nowrap;
	justify-content: space-between;
}
