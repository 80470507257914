.designerContainer {
    margin-top: 24px;
    margin-bottom: 40px;
}

.flex {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.designerRow {
    .grid {
        @extend .flex;
        width: 100%;
        .rangeColab {
            width: 22%;
        }
        .entityColab {
            margin-left: 10px;
            width: 22%;
        }
        .dateColab {
            @extend .flex;
            width: 56%;
            min-width: 700px;
            .dateColabInit {
                margin-left: 10px;
                width: 40%;
            }
            .dateColabStatus {
                margin-left: 10px;
                width: 40%;
            }
            .dateColabFinal {
                margin-left: 10px;
                width: 60%;
            }
            .delete {
                margin-left: 10px;
                margin-top: 0px;
                width: 10%;
            }
        }
    }
    .gridCecoc {
        @extend .flex;
        width: 100%;
        .rangeColab {
            width: 22%;
        }
        .entityColab {
            margin-left: 10px;
            width: 22%;
        }
        .dateColab {
            @extend .flex;
            width: 56%;
            min-width: 700px;
            .dateColabStatus {
                margin-left: 10px;
                width: 35.5%;
            }
            .dateColabFinal {
                margin-left: 10px;
                width: 90%;
            }
            .delete {
                margin-left: 10px;
                margin-top: 0px;
                width: 10%;
            }
        }
    }
}

.phiaDesigner,
.cecocDesigner {
    width: 100%;
}

.delete {
    margin: auto;
}