.buildingProcessesContainer {
  table {
    margin-top: 9px;
    width: 100%;

    tr {
      width: 50%;
      vertical-align: top;

      td:nth-child(1) {
        padding-right: 0.45%;
      }

      td:nth-child(2) {
        padding-left: 0.45%;
      }

      .titleValue1 {
        width: calc(25% - 12px);
      }

      .titleValue2 {
        width: calc(75% - 12px);
      }

      .titleValue3 {
        width: 58%;
      }

      .titleValue4 {
        width: 40%;
      }
    }
  }

  .buildingProcesses {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 24px;
  }

  .noDataPic {
    margin-top: 16px;
    text-align: center;

    img {
      width: 232px;
      height: 180px;
    }

    p {
      padding-top: 10px;
      width: 400px;
      margin: 16px auto;
    }
  }
}