
.addBtnContainer{
  position: relative;
  width: 100%;
  height: 32px;
  .addBtn {
    position: absolute;
    right: 0;
    width: fit-content;
  }
}

.buildingProcessTable {
  margin-top: 25px;
  width: 100%;

  tr {
    vertical-align: top;
    td:nth-child(1) {
      min-width: 110px;
      width: 10%;
    }
    td:nth-child(2) {
      padding-left: 21px;
      width: 40%;
    }
    td:nth-child(3) {
      padding-left: 21px;
      width: 25%;
    }
    td:nth-child(4) {
      padding-left: 21px;
      width: 25%;
    }
    td:nth-child(5) {
      padding-left: 21px;
    }
  }
}


.noDataPic {
  margin-top: 16px;
  text-align: center;

  img {
    width: 232px;
    height: 180px;
  }

  p {
    padding-top: 10px;
    width: 400px;
    margin: 16px auto;
  }
}