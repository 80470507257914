@-moz-document url-prefix() {
  .tdFlag {
    position: relative;

    .span {
      position: absolute;
      left: 2px;
      top: 1px;
    }
  }
}

.box {
  height: 100%;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 0;
  text-align: left;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title,
  .value {
    inline-size: 18vw;
    overflow-wrap: break-word;
    width: 100%;
  }


  .title {
    color: #696969;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;

    margin-bottom: 3px;
  }

  .value {
    color: #111111;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;


    margin-top: 5px;
  }

  .field {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    width: 90%;
  }

  .check {
    padding: 0 1% 0 5%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}